/**
 * @brief   TODO
 * @details Hinweis MFE:
 *          Um auf das jeweils benötigte Event "selectionChanged" reagieren
 *          zu können, muss je nach Modul, in welchem diese Komponente verwendet
 *          wird auch "peopleService", "institutionsService", etc. integriert
 *          werden. D.h. für jedes neue Modul, in welchem Dokumente hochgeladen
 *          werden sollen, muss diese Ergänzung erfolgen.
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// CDK & Angular Material
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Eigener Service
import {DocumentsService} from './../documents.service';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Module Services
import {GlobalHelpService} from '@global/components/global-help/global-help.service';
import {EventsService} from '@modules/events/events.service';
import {InstitutionsService} from '@modules/institutions/institutions.service';
import {PeopleService} from '@modules/people/people.service';
// Shared Services
import {GridService} from './../../grid/grid.service';
import {OverlayService} from './../../overlay/overlay.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {DocumentData, UploadFileData} from './../../document-data';
import {LooseObject} from './../../loose-object';
// Shared Components
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from '@shared/overlay/overlay-info/overlay-info-person/overlay-info-person.component';
import {hasOwn} from '@shared/utils';
import {PopupConfirmationComponent} from './../../popups/popup-confirmation/popup-confirmation.component';
import {Notification} from '@shared/notification';
import {FilterData} from '@shared/filter-data';
import {PopupFormularComponent} from '@shared/popups/popup-formular/popup-formular.component';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {DefaultReactiveComponent} from '@shared/default-reactive/default-reactive.component';
import {GridEvent} from '@shared/grid-new/shared/grid-new-event-types';
import {FileDownloadService} from '@global/services/file-download.service';
import {GridNewService} from '@shared/grid-new/shared/grid-new.service';

@Component({
    selector: 'phscw-documents-display',
    templateUrl: './documents-display.component.html',
    styleUrls: ['./documents-display.component.scss'],
})
export class DocumentsDisplayComponent extends DefaultReactiveComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('documentsForm', {static: false}) documentsForm: NgForm;
    // Name des anzusprechenden Controllers
    @Input() backendController: string;
    @Input() type: string;
    // ID des Grids
    @Input() gridId = 'documentsList';

    // ID des aktuell ausgewählten Eintrags (wird per Binding im HTML gesetzt)
    private _entityId: number;
    @Input()
    set entityId(value: number) {
        // Dokumente der vorherigen Entität nicht anzeigen
        this.gridData = [];
        // ID übernehmen
        this._entityId = value;
        // Daten laden
        this.loadData();
    }

    get entityId() {
        return this._entityId;
    }

    dataNotification: Notification;

    // Event das beim Ändern des Edit-Modes/Speichern/Abbrechen aufgerufen wird
    @Output() changeEditMode = new EventEmitter<boolean>();

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;
    // Edit Mode
    @Input() editMode = false;
    // Fehler bei Editieren eines Eintrags
    failedToEdit: any = false;
    // Daten für Grid
    gridData: DocumentData[] = [];
    // Einzelnes Dokument
    @Input() dataItem: DocumentData = null;

    /*
     * Spaltendefinitionen für Grid
     */
    gridColumns = [
        {
            columnDef: 'document-name',
            header: 'Dokument',
            cell: (element: DocumentData) => `${element.document_name}`,
            formatWidth: '150px',
            tooltip: true,
        },
        {
            columnDef: 'document-description',
            header: 'Beschreibung',
            cell: (element: DocumentData) => `${element.document_description || ''}`,
            formatWidth: '150px',
            tooltip: true,
        },
        {
            columnDef: 'document-date',
            header: 'Datum',
            cell: (element: DocumentData) => `${element.create_date}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'valid_from',
            header: 'Gültig von',
            cell: (element: DocumentData) => `${element.valid_from}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'valid_to',
            header: 'Gültig bis',
            cell: (element: DocumentData) => `${element.valid_to}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'document-uploader',
            header: 'Benutzer',
            cell: (element: DocumentData) => {
                if (element.employee_firstname && element.employee_lastname) {
                    return element.employee_firstname + ' ' + element.employee_lastname;
                }
                if (element.employee_username) {
                    return element.employee_username;
                }
                return '';
            },
        },
        {
            columnDef: 'document-person',
            header: 'Person',
            cell: (element: any) => {
                if (element.person_firstname) {
                    return `${element.person_lastname || ''}, ${element.person_firstname || ''} `;
                }
                return `${element.person_lastname || ''}`;
            },
            formatWidth: '150px',
            tooltip: true,
        },
        {
            columnDef: 'document-institution',
            header: 'Einrichtung',
            cell: (element: any) => `${element.institution_name1 || ''}`,
            formatWidth: '100px',
            tooltip: true,
        },
        {
            columnDef: 'document-category',
            columnField: 'document_category',
            header: 'Kategorie',
            cell: (element: any) => `${element.document_category || ''}`,
            formatTemplate: 'listentries',
            listentry: 'documentCategory',
        },
        {
            columnDef: 'document-status',
            columnField: 'document_status',
            header: 'Status',
            cell: (element: any) => `${element.document_status || ''}`,
            formatTemplate: 'listentries',
            listentry: 'documentStatus',
        },
    ];

    // Anzuzeigende Spalten für Grid
    @Input() gridDisplayedColumns = [
        'document-date',
        'document-institution',
        'document-person',
        'document-name',
        'valid_to',
        'document-description',
        'document-category',
        'document-status',
        'edit-delete',
    ];

    gridFilterFields: FilterData = {
        label: 'Erweiterter Filter',
        key: 'advanced',
        display: 'popup',
        popupTitle: 'Erweiterter Filter - Rechnungen',
        formular: {
            type: 'formular',
            data: this.documentsService.inputFields,
        },
    };

    // aktueller Benutzer
    currentUser: LooseObject = {};
    // Berechtigung "allowEditAllDocuments"
    allowEditAllDocuments = false;
    @Input() ignoreFilter = false;

    /*
     * Neuer Modus, der aktuell nur bei Opportunities benutzt wird:
     * Zwei Tabs, Liste und Details. Für die Liste wird das neue Grid verwendet und für die Detailansicht
     * wird diese Komponente benutzt aber nur für die Änderung der Formularfelder
     *
     * Da hier die Grid-Daten nicht mehr direkt von der Komponente geladen werden, wird
     * die Funktion "loadData" nicht mehr aufgerufen.
     *
     * "gridItem" muss vorher durch den parent gesetzt sein, z.B. durch Auswahl einer Zeile im Grid
     *
     * TODO: readonly
     */
    @Input() useNewDisplayMode: boolean = false;

    /*
     * Name der einbindenden Komponente
     * Um leichtere Kommunikation mit der parent-Komponente zu ermöglichen
     *
     * z.B. wenn sich Daten ändern, kann in der Parent-Komponente das Grid aktualisiert werden
     */
    @Input() parentGridName: string | null = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param userPermissionsService
     * @param initService
     * @param storageService
     * @param documentsService
     * @param gridService
     * @param overlayService
     * @param dialog
     * @param institutionsService
     * @param peopleService
     * @param eventsService
     * @param globalHelpService
     * @param translateService
     * @param filterDialog
     * @param toolbarService
     * @param gridNewService
     * @param fileDownloadService
     * @param cdr
     */
    constructor(
        private appCore: AppCoreService,
        private userPermissionsService: UserPermissionsService,
        private initService: InitService,
        private storageService: StorageService,
        private documentsService: DocumentsService,
        private gridService: GridService,
        private overlayService: OverlayService,
        private dialog: MatDialog,
        private institutionsService: InstitutionsService,
        private peopleService: PeopleService,
        private eventsService: EventsService,
        private globalHelpService: GlobalHelpService,
        private translateService: TranslateService,
        private filterDialog: MatDialog,
        private toolbarService: ToolbarService,
        private fileDownloadService: FileDownloadService,
        private gridNewService: GridNewService<UploadFileData>,
    ) {
        super();
    }

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Benutzer aus Storage laden
        this.loadUserData();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Berechtigung "allowEditAllDocuments" prüfen
        this.checkAllowEditAllDocuments();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
        this.dataNotification = new Notification();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream([
                'GENERAL.DOCUMENT',
                'GENERAL.DESCRIPTION',
                'GENERAL.DATE',
                'GENERAL.USER',
                'GENERAL.PERSON',
                'GENERAL.INSTITUTION',
                'GENERAL.CATEGORY',
                'GENERAL.STATUS',
                'GENERAL.VALIDFROM',
                'GENERAL.VALIDTO',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'document-name').header =
                    translation['GENERAL.DOCUMENT'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-description').header =
                    translation['GENERAL.DESCRIPTION'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-date').header =
                    translation['GENERAL.DATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-uploader').header =
                    translation['GENERAL.USER'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-person').header =
                    translation['GENERAL.PERSON'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-institution').header =
                    translation['GENERAL.INSTITUTION'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-category').header =
                    translation['GENERAL.CATEGORY'];
                this.gridColumns.find((column: any) => column.columnDef === 'document-status').header =
                    translation['GENERAL.STATUS'];
                this.gridColumns.find((column: any) => column.columnDef === 'valid_from').header =
                    translation['GENERAL.VALIDFROM'];
                this.gridColumns.find((column: any) => column.columnDef === 'valid_to').header =
                    translation['GENERAL.VALIDTO'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Status-Definitionen laden, wenn Listentries vollständig geladen wurden
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.loadUserData();
            }
        });

        // Event "selectionChanged" von "peopleService" oder "institutionsService"
        if (this.type === 'institutions') {
            this.institutionsService.selectionChanged
                .pipe(takeUntil(this._componentDestroyed$))
                .subscribe((result: number) => {
                    this.onSelectionChanged(result);
                });
        } else if (this.type === 'people') {
            this.peopleService.selectionChanged
                .pipe(takeUntil(this._componentDestroyed$))
                .subscribe((result: number) => {
                    this.onSelectionChanged(result);
                });
        } else if (this.type === 'events') {
            this.eventsService.selectionChanged
                .pipe(takeUntil(this._componentDestroyed$))
                .subscribe((result: number) => {
                    this.onSelectionChanged(result);
                });
        } else if (this.type === 'help') {
            this.globalHelpService.eventHelpSelectionChanged
                .pipe(takeUntil(this._componentDestroyed$))
                .subscribe((result: CWEvent) => {
                    // Event-Daten
                    const event: CWEvent = result;
                    // Abbruch, falls das Event nicht für die eigene Komponente ist
                    if (event.sender !== 'global-help-lists' || hasOwn(event.data, 'id') === false) {
                        return;
                    }
                    this.onSelectionChanged(event.data.id);
                });
        }

        // Event "eventGridRowEditClicked" von "GridService"
        this.gridService.eventGridRowEditClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventGridRowEditClicked(event);
            });

        // Event "eventGridRowEditClicked" von "GridService"
        this.gridService.eventGridRowEditClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventGridRowEditClicked(event);
            });

        // Event "eventGridRowDeleteClicked" von "GridService"
        this.gridService.eventGridRowDeleteClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventGridRowDeleteClicked(event);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.target !== this.gridId) {
                    return;
                }
                this.onEventGridPageCounterChanged(event);
            });

        // Event "appDataChanged" von "DocumentsService"
        this.appCore.appDataChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht für die eigene Komponente ist
            if (event.sender !== this.backendController) {
                return;
            }
            this.onAppDataChanged();
        });

        // TODO
        this.toolbarService.eventGridExtensionToggle
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                // Prüfung, of der Kontaktfilter gedrückt wurde
                if (event.data && event.data.extension === 'filter' && event.target === 'documents-upload-display') {
                    // Dialog konfigurieren und öffnen
                    const dialogRef = this.filterDialog.open(PopupFormularComponent, {
                        width: '450px',
                        data: {
                            title: 'Filter',
                            message: '',
                            data: this.documentsService.inputFields,
                        },
                    });
                    // Auf das Schließen des Dialogs reagieren
                    dialogRef.afterClosed().subscribe((result: any) => {
                        this.onFilterDialogAnswer(result);
                    });
                }
            });
    }

    /**
     *
     * @param popupResult
     */
    onFilterDialogAnswer(popupResult: any) {
        if (popupResult.answer == 'yes') {
            delete popupResult.answer;
            this.resetData();

            const keyValuePair = {};

            this.documentsService.inputFields.forEach((field) => {
                keyValuePair[field.field_id] = field.field_type;
            });
            this.loadData({
                filter: popupResult,
                definition: keyValuePair,
            });
        }
    }

    /**
     * @brief   Lade Daten des authentifizierten Benutzers
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadUserData(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((userData: any) => {
            // Falls Daten des Users vorhanden sind
            if (userData) {
                // Daten zwischenspeichern
                this.currentUser = userData;
            }
        });
    }

    /**
     * Auf geänderte Auswahl in Modul-Liste reagieren
     * @param id
     */
    onSelectionChanged(id: number): void {
        // Falls sich evtl. ein Dokument im editMode befindet, wird dies abgebrochen
        this.clickCancel();

        // Daten zurücksetzen
        this.resetData();

        // Falls bereits geladene Entität erneut angeklickt wird
        if (id === this._entityId) {
            // Daten laden
            this.loadData();
        }
    }

    /**
     * Auf Stift-Icon-Klick im Grid reagieren (Dokument bearbeiten)
     * @param event
     */
    onEventGridRowEditClicked(event: CWEvent): void {
        // 2018-12-06, PhS(MFe): Kopie des angeklickten Items, nicht mit Data aus Event direkt arbeiten, sonst direkt mit Grid verbunden
        this.dataItem = <DocumentData>JSON.parse(JSON.stringify(event.data.selectedRow));

        // EditMode aktivieren
        this.editMode = true;
        this.failedToEdit = false;
        this.changeEditMode.emit(this.editMode);
    }

    /**
     * Im NEUEN Grid wurde eine Zeile angeklickt
     * @param event
     */
    onEventGridNewRowClicked(event: GridEvent<DocumentData>): void {
        this.dataItem = event.entity;

        // EditMode aktivieren
        this.editMode = true;
        this.failedToEdit = false;
        this.changeEditMode.emit(this.editMode);
    }

    /**
     * auf Toolbar-Klick reagieren (Dokument löschen)
     * @param event
     */
    onEventGridRowDeleteClicked(event: CWEvent): void {
        this.dataItem = event.data.selectedRow;
        this.openDeleteDialog();
    }

    /**
     * auf neue Daten reagieren
     */
    onAppDataChanged(): void {
        this.resetData();
        this.loadData();
    }

    /**
     * Es wurde auf "Mehr laden..." geklickt
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * alle Dokumente der Entität laden
     * @param filter
     */
    loadData(filter = null): void {
        // Im neuen Anzeige-Modus sind die Grid-Daten bereits vorhanden
        if (this.useNewDisplayMode) {
            return;
        }

        // Funktion "Mehr laden..." wird wieder deaktiviert
        this.loadMoreEnabled = false;

        const postBody = {
            entityId: this._entityId,
            gridPage: this.gridPage,
            limit: 5,
            type: this.type,
            ignoreRegions: true,
        };
        // Daten übeer Service-Request anfordern
        let mergedObj = postBody;

        if (!this.ignoreFilter) {
            mergedObj = {
                ...postBody,
                ...filter,
            };
        }

        // const serviceRequest$ = this.documentsService.loadData(this._entityId, this.gridPage, this.type, filter);
        const serviceRequest$ = this.documentsService.loadData(mergedObj);
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Daten geladen wurden
            if (
                'documents' in result.data &&
                typeof result.data['documents'] !== 'undefined' &&
                result.data['documents'] !== null &&
                result.data['documents'].length > 0
            ) {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Entität passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Entität gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this._entityId != result.data['id']) {
                    return;
                }

                // Berechtigung zum Bearbeiten prüfen und Wert setzen
                if (!this.allowEditAllDocuments) {
                    result.data['documents'].forEach((document: LooseObject) => {
                        if (this.currentUser['employee_id'] !== document['employee_id']) {
                            document.locked = true;
                        }
                    });
                }

                // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                if (JSON.stringify(this.gridData) != JSON.stringify(result.data['documents'])) {
                    // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                    this.gridData = this.gridData.concat(result.data['documents']);
                }
                // Funktion "Mehr laden..." wird wieder aktiviert
                this.loadMoreEnabled = true;
            } else {
                // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                this.loadMoreVisible = false;
            }

            // Anzahl zwischenspeichern
            this.documentsService.setDocumentsCount(this.gridData.length);

            // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
            this.editMode = false;
        });
    }

    /**
     * Berechtigung "allowEditAllDocuments" prüfen
     */
    checkAllowEditAllDocuments(): void {
        // Berechtigung laden
        const allowEditAllDocuments: boolean = this.userPermissionsService.getPermissionValue('allowEditAllDocuments');
        this.allowEditAllDocuments = allowEditAllDocuments;
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     */
    clickSubmit(): void {
        // nur gültige Formulare werden submitted
        if (this.documentsForm.form.valid) {
            const formData = this.documentsForm.form.value;
            formData.Notifications = this.dataNotification;
            // Submit der Formular-Daten über documentsService
            const serviceRequest$ = this.documentsService.saveDocument(this.dataItem.id, formData);
            serviceRequest$.subscribe((result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Entität passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Entität gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this.dataItem.id != result['data']['id']) {
                    return;
                }

                // Request erfolgreich?
                if (result['success']) {
                    // EditMode verlassen
                    this.editMode = false;
                    this.failedToEdit = false;
                    this.changeEditMode.emit(this.editMode);

                    // Liste neu laden im neuen Grid
                    this.gridNewService.dataChanged(this.parentGridName, this.parentGridName, result.data.data);

                    // 2018-12-06, PhS(MFe): Nach erfolgreichem Speichern werden die Daten (Liste der Dokumente) resettet und neu geladen
                    this.resetData();
                    this.loadData();
                } else {
                    this.failedToEdit = result['message'];
                }
            });
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // Daten zurücksetzen
        /**
         * 2018-12-06, PhS(MFe):
         * Die Funktion "clickCancel" wird in dieser Component immer aufgerufen,
         * wenn im Grid (E-Liste / P-Liste) eine andere Zeile ausgewählt wurde.
         *
         * "loadData" wurde hier auskommentiert, da sonst erst nochmal für die
         * alte (innerhalb der Component noch aktuell vorhandene) ID die Daten
         * geladen werden.
         */
        // this.loadData();

        // EditMode verlassen
        this.editMode = false;
        this.failedToEdit = false;
        this.changeEditMode.emit(this.editMode);
        this.resetData();
        this.loadData();
    }

    /**
     * Dokument löschen
     * @param result
     */
    deleteDocument(result: any): void {
        // wurde Löschen bestätigt?
        if (result && result.answer === 'yes') {
            // Submit der Formular-Daten über documentsService
            const serviceRequest$ = this.documentsService.deleteDocument(this.dataItem.id, this.type);
            serviceRequest$.subscribe((result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Entität passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Entität gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this.dataItem['id'] != result['data']['document_id']) {
                    return;
                }

                // Neu laden
                this.resetData();
                this.loadData();

                /*
                 * Grid über Löschung informieren
                 * Anschließend zur Listenansicht wechseln durch Änderung von editMode
                 */
                if (this.useNewDisplayMode) {
                    this.gridNewService.dataDeleted(this.parentGridName, this.parentGridName, result.data);
                    this.changeEditMode.emit(false);
                }
            });
        }
    }

    /**
     * Daten zurücksetzen (z.B. wenn Person gewechselt wird)
     */
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.gridData = [];
        this.dataItem = null;
    }

    /**
     * Dialog öffnen
     */
    openDeleteDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.DOCUMENTS.DISPLAY.DELETEDOCUMENT'),
                message: this.translateService.instant('SHARED.DOCUMENTS.DISPLAY.DELETEDOCUMENTQUESTION'),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => this.deleteDocument(result));
    }

    /*
     * @brief   Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openOverlayInfoDialog(event: any, backendController: string, entityId: number): void {
        // Dialog öffnen
        let dialogRef = null;
        if (backendController === 'PeopleData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoPersonComponent,
                backendController,
                'details',
                entityId,
            );
        } else if (backendController === 'InstitutionsData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoInstitutionComponent,
                backendController,
                'details',
                entityId,
            );
        } else {
            console.error('backendController unknown');
            return;
        }

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe();
    }

    /**
     * Erzeugt die Benachritigungstext, potenziell ins Backend verschieben
     */
    generateDocumentNotification() {
        // const translatedType = this.translateService.instant('GENERAL.' + this.type.toUpperCase());
        return 'Dokument "' + this.dataItem.document_name + '"';
    }

    /**
     * Dokument herunterladen
     */
    clickDownload() {
        this.documentsService.getDocument(this.dataItem.id).subscribe({
            next: (result: Blob) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, this.dataItem.document_name);
            },
        });
    }
}
