// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Eigenen Service einbinden
import {GlobalMenuService} from './global-menu.service';
// Globale Services einbinden
import {AppCoreService} from '@global/services/app-core.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {MenuData} from '@shared/menu-data';
import {hasOwn, extractFirstWord} from '@shared/utils';
import {environment} from '@environment';

@Component({
    selector: 'phscw-global-menu',
    templateUrl: './global-menu.component.html',
    styleUrls: ['./global-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * @brief   Komponent für globales Menü.
 * @author  Olga Salomatina <o.salomatina@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
export class GlobalMenuComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Definiert, ob das globale Menü aus- oder eingeklappt ist
    public showGlobalMenuTexts = true;
    // Definiert, an welcher Position der Tooltip angezeigt wird
    public tooltipPosition = 'right';

    // Menüpunkte für globales Menü werden über Backend geladen
    public globalMenu: MenuData[] = []; // Menüpunkte für globales Menü werden über Backend geladen
    public submodules: any[] = [];

    /*
     * Pfade für Kunden-Logos
     * Standardmäßig Pharmakon-Logo
     */
    public bigLogoPath: string = 'assets/img/logos/logo_pharmakon_white.svg';
    public smallLogoPath: string = 'assets/img/logos/logo_pharmakon_white_icon.svg';

    // Aktuelle Sprache
    langKeyReference = '';
    activeSubMenu = '';
    /*
     * Durch Erweiterung mit Child-Menüs fielen Direktiven RouterLink und
     * RouterLinkAktive aus dem Template raus. Um im globalen Menü trotzdem
     * anzeigen zu können, welche Route gerade aktiv ist, wird der Name der
     * aktiven Route hier zwischengespeichert.
     */
    public activeRouteName: string;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param router
     * @param globalMenuService
     * @param userSettingsService
     * @param userPermissions
     * @param changeDetector
     * @param translate
     */
    constructor(
        private appCore: AppCoreService,
        private router: Router,
        private globalMenuService: GlobalMenuService,
        private userSettingsService: UserSettingsService,
        private userPermissions: UserPermissionsService,
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Environment prüfen
        this.checkFeatureConfiguration();

        // aktuelle Sprache laden
        this.langKeyReference = this.translate.currentLang;

        // Übersetzungen subscriben
        this.initializeEventSubscriptions();

        // Menü-Daten über Service laden
        const serviceRequest$ = this.globalMenuService.loadData();
        serviceRequest$.subscribe((result) => {
            this.initMenu(result['data']['menu'], result['data']['submodules']);
        });

        // Benutzer-Einstellung "globalMenuExpanded" definiert initialen Zustand des globalen Menüs
        this.showGlobalMenuTexts = this.userSettingsService.getValue<boolean>('globalMenuExpanded');
        // Event auslösen um korrekte Breite für Inhalt zwischen Menüs zu setzen
        this.globalMenuService.globalMenuToggled('globalMenu', this.showGlobalMenuTexts);

        // Aktiven Menüpunkt direkt bei Initialisierung markieren
        const presetInitModule: string = this.userPermissions.getPermissionValue('presetInitModule');
        if (presetInitModule && presetInitModule.length > 0) {
            // Berechtigung definiert aktive Route
            this.activeRouteName = extractFirstWord(presetInitModule);
        } else {
            // Zuletzt ausgewähltes Modul definiert aktive Route
            this.activeRouteName = extractFirstWord(this.userSettingsService.getValue<string>('lastModule'));
        }

        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                /**
                 * 2020-08-31, PhS(MFe):
                 * Zwei Zeilen deaktiviert, da das Hervorheben der Menüpunkte ohne
                 * manuelle Eingriffe in Route-String bereits funktioniert.
                 * Die zwei unnötigen Zeilen führten stattdessen dazu, dass Sub-
                 * module, die auf oberster Ebene eingeblendet werden sollten (z. B.
                 * bei BioNTech Auftragsübersicht), nicht beim ersten Klick
                 * hervorgehoben werden konnten.
                 */
                /**
                 * 2020-10-20, PhS(TH):
                 * Erste Zeile wieder aktiviert, da durch das Fehlen dieser Zeile
                 * das Hervorheben bei "Weiterleitung" nicht mehr funktioniert hat.
                 */
                // Aktive Route manuell updaten (für visuelle Hervorherbung im Menü, noch bevor gespeichert wurde)
                this.activeRouteName = extractFirstWord(route.url.toString());
                // eventuelle Child-route finden und entfernen
                // this.activeRouteName = this.activeRouteName.replace(/\/.*/, '');
                // Geänderte Route als "lastModule" in Benutzer-Einstellungen speichern
                // (asynchron, damit weiteres Laden des Moduls nicht blockiert wird)
                if (route.url.toString() !== '/login') {
                    this.userSettingsService.setValue<string>('lastModule', route.url.toString(), true, true);
                }
                // Change-Detection manuell auslösen
                this.changeDetector.detectChanges();
                // AppCore informieren (z.B. da es sonst bei CrossModule passieren kann, dass das aufgerufene Modul keine Infos zu Submodulen erhält
                this.sendAppCoreGlobalMenuChanged();
            });
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Min Hye Park <m.park@pharmakon.software>
     */
    initializeEventSubscriptions() {
        // Auf Ändern der Sprache reagieren
        this.translate.onLangChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.langKeyReference = result.lang;
        });
    }

    /**
     * @param menuResult
     * @param subModulesResult
     * @brief   Funktion für Menü initialisieren
     * @details Hier werden u.a. auch die Modul-Berechtigungen geprüft und
     *          Module ausgeblendet.
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    initMenu(menuResult: any, subModulesResult: any) {
        this.submodules = this.deleteNullValues(subModulesResult);
        menuResult = this.deleteNullValues(menuResult);

        // Überprüfen welche Menüeinträge fpr den User erlaubt sind
        this.globalMenu = this.checkMenuPermissions(menuResult);

        /**
         * Nach dem Laden der globalen Menü-Einträge, muss das initiale
         * Highlighting des ausgewählten Menüpunkts geprüft werden.
         */
        let menuFound = false;
        for (let i = 0; i < this.globalMenu.length; i++) {
            if (this.globalMenu[i].name === this.activeRouteName) {
                menuFound = true;
                continue;
            }
        }
        if (menuFound === false) {
            // Eventuelle Child-route finden und entfernen, damit übergeordneter Menüpunkt hervorgehoben werden kann
            this.activeRouteName = extractFirstWord(this.activeRouteName);
        }

        // 2019-01-22, PhS(MFe): sobald die Menü-Daten geladen wurden, muss auch appCore informiert werden
        this.sendAppCoreGlobalMenuChanged();
        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * @param modules
     * @param parentName
     * @brief   Überprüft Menueinträge, damit nur erlaubte Menueinträge angezeigt werden
     * @details Läuft rekursiv über alle children und subModules {$configArray['cw']['menu']['global']} um die Permission auszuwerten
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    checkMenuPermissions(modules: any[], parentName = undefined) {
        const result = [];

        for (const [key, value] of Object.entries(modules)) {
            // notwendige Strukturen alter Konfiguration wieder herstellen
            if (typeof value === 'object') {
                value['name'] = key;
                if (parentName != undefined) {
                    value['parentName'] = parentName;
                }
            }

            if (value.permission == undefined) {
                if (parentName == undefined) {
                    value.permission = this.camelize('enable-module-' + key);
                } else {
                    value.permission = this.camelize('enable-module-' + parentName + '-' + key);
                }
            }

            // Wenn in der config für das module eine permission gesetzt ist
            if ('permission' in value && !('skipPermission' in value)) {
                const permission: any = this.userPermissions.getPermissionValue(value.permission);

                // string permissions werden als liste von modulen interpretiert, getrennt mit Pipes ('|')
                if (typeof permission === 'string') {
                    const splittedPermission = permission.split('|');
                    if (!splittedPermission.includes(value.name) || permission == '') {
                        // wird dieses Module nicht hinzugefügt
                        continue;
                    }
                }

                // und diese Permisson false und nicht null ist (falls keine Permission exisitieren sollte, trotzdem anzeigen)
                if (typeof permission === 'boolean' && !permission) {
                    // wird dieses Module nicht hinzugefügt
                    continue;
                }

                // Kein Wert bei einer Permission und kein defaultvalue gesetzt, auch nicht anzeigen
                if (permission == null) {
                    // wird dieses Module nicht hinzugefügt
                    continue;
                }
            }

            // kopieren des Eintrages in der Backend Config
            const partResult = JSON.parse(JSON.stringify(value));
            // löschen der Untereinträge, werden wieder hinzugefügt (falls berechtigt) in der Rekursion
            delete partResult['submodules'];
            delete partResult['children'];

            // falls module children hat rekursion starten
            if (value !== undefined && 'children' in value) {
                const children = this.checkMenuPermissions(value['children'], key);

                partResult['children'] = children;
            }

            // kombinieren des TEilergebnis mit Hauptergebnis
            result.push(partResult);
        }

        // sortieren der Arrays anhand des sort attributes
        result.sort(this.sortModules);

        return result;
    }

    /**
     * @brief   Funktion zum Ein-/Ausklappen des globalen Menüs
     * @details Status des Menüs wird in Benutzereinstellungen gespeichert
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    toggleGlobalMenu() {
        this.showGlobalMenuTexts = !this.showGlobalMenuTexts;
        this.userSettingsService.setValue<boolean>('globalMenuExpanded', this.showGlobalMenuTexts);

        // Event auslösen um korrekte Breite für Inhalt zwischen Menüs zu setzen
        this.globalMenuService.globalMenuToggled('globalMenu', this.showGlobalMenuTexts);
    }

    /**
     * @param menuItem
     * @brief   Eigene Click-Funktion für Items im globalen Menü
     * @details Notwendig aufgrund der Child-Menüs, da Routenwechsel nicht
     *          mehr einfach über Direktiven "RouterLink" und "RouterLinkActive"
     *          möglich ist.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    onClickMenu(menuItem: MenuData) {
        // Prüfe, ob angeklicktes Item noch Child-Einträge hat
        if (!menuItem.children || menuItem.children.length === 0) {
            this.activeSubMenu = '';
            // Menü ohne Children --> zu Menüpunkt navigieren
            this.executeNavigation(menuItem.name, '');
        } else {
            // Menü mit Children --> Child-Menü anzeigen
            menuItem.showChildren = !menuItem.showChildren;
        }
    }

    /**
     * Eingebundenes OverlayMenu liefert Klick auf Submenü-Item
     * @param subMenuItem
     */
    onClickSubMenu(subMenuItem: MenuData) {
        this.activeSubMenu = subMenuItem.name;
        // Zu Parent-Route navigieren & Child-Name als Paramter mitgeben
        this.executeNavigation(subMenuItem.parentName, subMenuItem.name);
    }

    /**
     * @param route
     * @param childRoute
     * @brief   Navigation ausführen
     */
    executeNavigation(route: string, childRoute: string) {
        this.activeRouteName = route;
        // Router-Link zusammenbauen
        const routerLink: string = '/' + route;
        // Route-Navigation ausführen
        if (childRoute !== '') {
            this.router.navigate([routerLink, childRoute]);
        } else {
            this.router.navigate([routerLink]);
        }
        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * @brief   Event über AppCore auslösen, damit andere Komponenten vom
     *          gewechselten Hauptmodul erfahren. Dies wird hauptsächlich benötigt,
     *          damit z.B. Hauptmodul "people" über die anzuzeigenden Submodule
     *          informiert wird.
     * @details Ziel (target) enstpricht der aktuell aktivierten Route.
     *              z.B.: '/people';
     *                    '/reports/dailyreport';
     *          Die Menü-Daten werden mitgesendet.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    sendAppCoreGlobalMenuChanged() {
        const eventData: CWEvent = {
            sender: 'globalmenu',
            target: this.activeRouteName,
            data: {
                globalMenu: this.globalMenu,
                submodules: this.submodules,
            },
        };
        this.appCore.globalMenu = this.globalMenu;
        this.appCore.submodules = this.submodules;
        this.appCore.globalMenuChanged.next(eventData);
    }

    /**
     * @brief Übersetzung
     * @param menuItem
     * @param childMenuItem
     * @returns string
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    translateMenuItemToTranslationKey(menuItem: MenuData, childMenuItem: MenuData = undefined) {
        if (!menuItem.name) {
            return '';
        }
        let result = 'GLOBAL.MENU.' + menuItem.name.toUpperCase();

        if (childMenuItem != undefined && childMenuItem.name) {
            result += '_' + childMenuItem.name.toUpperCase();
        }

        return result;
    }

    /**
     * @param a
     * @param b
     * @brief  Callback Methode zum Sortieren der Module Arrays
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    sortModules(a: any, b: any) {
        // equal items sort equally
        if (a.sort === b.sort) {
            return 0;
        } if (!hasOwn(a, 'sort')) {
            return 1;
        } if (!hasOwn(b, 'sort')) {
            return -1;
        }
        // otherwise, if we're ascending, lowest sorts first
        return a.sort < b.sort ? -1 : 1;
    }

    deleteNullValues(obj: any) {
        return JSON.parse(
            JSON.stringify(obj, (key, value) => (value === null ? undefined : value)),
        );
    }

    camelize(str) {
        const arr = str.split('-');
        const capital = arr.map((item, index) => (index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()));
        // ^-- change here.
        const capitalString = capital.join('');

        return capitalString;
    }

    private checkFeatureConfiguration() {
        // Kunden Logo für ausgeklapptes Menü
        if (hasOwn(environment, 'customerBigLogoPath') && environment.customerBigLogoPath.length > 0) {
            this.bigLogoPath = environment.customerBigLogoPath;
            this.smallLogoPath = this.bigLogoPath;
        }

        /*
         * Kunden Logo für eingeklapptes Menü
         * Wenn nur das große Logo gesetzt ist, wird das kleine Logo gleich gesetzt
         */
        if (hasOwn(environment, 'customerSmallLogoPath') && environment.customerSmallLogoPath.length > 0) {
            this.smallLogoPath = environment.customerSmallLogoPath;
        }
    }
}
