/* eslint-disable no-console */
/* eslint-disable @stylistic/object-property-newline */
import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {CwIcon, IconType} from '@shared/cw-icon';
import {hasOwn} from '@shared/utils';

/**
 * Service zur Verwaltung von Icons ohne Caching.
 */
@Injectable({providedIn: 'root'})
export class IconService {
    // Füge eine Cache-Property hinzu, um geladene Listen zwischenzuspeichern
    private listCache: Map<string, Promise<any[]>> = new Map<string, Promise<any[]>>();

    constructor(private storageService: StorageService) {}

    /**
     * Lädt mehrere Listen ohne sie zwischenzuspeichern.
     * @param {string[]} listNames Ein Array von Listennamen, die geladen werden sollen.
     * @returns {Promise} Ein Promise, das zu einem Objekt aufgelöst wird, welches die geladenen Listen enthält.
     */
    public loadLists(listNames: string[]): Promise<{[listName: string]: any[]}> {
        const loadPromises = listNames.map((listName) => this.getList(listName));

        // Wartet, bis alle Listen geladen sind, und gibt sie als Objekt zurück
        return Promise.all(loadPromises).then((lists) => {
            const result: {[listName: string]: any[]} = {};
            listNames.forEach((listName, index) => {
                result[listName] = lists[index];
            });
            return result;
        });
    }

    /**
     * Ruft die Liste für einen gegebenen listName ab.
     * Falls die Liste bereits geladen wurde, wird der Cache verwendet.
     * @param {string} listName Der Name der Liste.
     * @returns {Promise<any[]>} Ein Promise, das zu der Liste von Elementen aufgelöst wird.
     */
    public getList(listName: string): Promise<any[]> {
    // Prüfe, ob die Liste bereits im Cache vorhanden ist
        if (this.listCache.has(listName)) {
            return this.listCache.get(listName);
        }

        // Falls nicht, lade die Liste und speichere sie im Cache
        const promise = this.storageService
            .getItem('listentries|' + listName)
            .then((storageData) => this.processStorageData(storageData));

        this.listCache.set(listName, promise);
        return promise;
    }

    /**
     * Verarbeitet die aus dem Speicher abgerufenen Daten.
     * @param storageData Die aus dem Speicher abgerufenen Daten.
     * @returns Die verarbeitete Liste von Elementen.
     */
    private processStorageData(storageData: any): any[] {
        if (!storageData) {
            // keine Daten gefunden
            return [];
        }

        // TODO: weitere Verarbeitung (?)

        // Gibt die gespeicherten Daten unverändert zurück
        return storageData;
    }

    /**
     * Mappt den iconType-String aus den Listendaten auf den IconType-Enum.
     * Standardmäßig wird IconType.FontAwesome verwendet, falls keine Übereinstimmung gefunden wird
     * @param {string} iconType Der iconType-String aus den listentries.
     * @returns {IconType} Der Wert des IconType-Enums.
     */
    mapIconType(iconType: string): IconType {
        // Überprüft, ob iconTypeStr einem Wert im IconType-Enum entspricht
        if (iconType && Object.values(IconType).includes(iconType as IconType)) {
            return iconType as IconType;
        }
        // Standardmäßig IconType.FontAwesome
        return IconType.FontAwesome;
    }

    /**
     * Parst die icon-Daten aus den Listendaten und gibt ein CwIcon-Objekt zurück
     * @param {string | {iconName: string; iconType: string}} listData Die Listendaten
     * @param {CwIcon} fallbackIcon Ein optionales Icon, das verwendet wird, wenn kein gültiges Icon gefunden wird
     * @returns {CwIcon} Ein CwIcon-Objekt mit den Daten aus der Liste
     *                   Wenn die Daten nicht verarbeitet werden können, wird ein Standard-Icon oder das Fallback-Icon zurückgegeben
     */
    parseIcon(listData?: string | {iconName: string; iconType: string; iconPath?: string}, fallbackIcon?: CwIcon): CwIcon {
        const notFoundIcon: CwIcon = {
            iconName: 'notdef',
            iconType: IconType.FontAwesome,
        };

        if (!listData) {
            return fallbackIcon || notFoundIcon;
        }

        let iconData: {iconName: string; iconType: string; iconPath?: string};

        // Versuchen, die Daten zu einem JSON-Objekt zu konvertieren
        if (typeof listData === 'string') {
            try {
                iconData = JSON.parse(listData);
            } catch {
                // Wenn die Daten nicht verarbeitet werden können, wird das Standard-Icon oder Fallback-Icon zurückgegeben
                return fallbackIcon || notFoundIcon;
            }
        } else {
            // Wenn die Daten bereits als Objekt übergeben wurden
            iconData = listData;
        }

        /*
         * Wenn die Daten nicht über das notwendige Format verfügen
         * (kein iconName oder kein iconType)
         */
        if (iconData && hasOwn(iconData, 'icon')) {
            console.warn('Icon-Eigenschaft "icon" vorhanden. Bitte zu "iconName" umbenennen.', iconData);
        } else if (iconData && !hasOwn(iconData, 'iconName')) {
            console.warn('Icon-Eigenschaft "iconName" fehlt. Es wird ein Standard-Icon verwendet.', iconData);
            return fallbackIcon || notFoundIcon;
        }

        // Die Daten werden in ein CwIcon-Objekt umgewandelt
        return {
            iconName: iconData.iconName,
            iconType: this.mapIconType(iconData.iconType),
            iconPath: iconData.iconPath,
        };
    }

    /**
     * Ruft ein Icon basierend auf einem Listeneintrag ab.
     * Sucht in der angegebenen Liste (unter Verwendung des Caches) nach einem Eintrag,
     * bei dem "list_key" mit dem übergebenen listKey übereinstimmt,
     * und gibt das daraus geparste Icon zurück.
     * @param {string} listName Der Name der Liste, die geladen werden soll.
     * @param {string} listKey Der Schlüssel, nach dem in der Liste gesucht wird.
     * @param {CwIcon} [fallbackIcon] Optionales Fallback-Icon, falls kein passender Eintrag gefunden wird.
     * @returns {Promise<CwIcon>} Ein Promise, das das zugehörige Icon auflöst.
     */
    public async getIconForListentry(
        listName: string,
        listKey: string,
        fallbackIcon?: CwIcon,
    ): Promise<CwIcon> {
    // Lade die Liste (der Cache wird in getList genutzt)
        const list = await this.getList(listName);

        // Suche in der Liste (als Array) nach einem Eintrag, der den passenden list_key besitzt
        if (list && Array.isArray(list)) {
            const entry = list.find((item) => item['list_key'] === listKey);
            if (entry) {
            // Parse die Icon-Daten aus dem Eintrag und gebe das Icon zurück
                return this.parseIcon(entry.list_data, fallbackIcon);
            }
        }

        // Falls kein passender Eintrag gefunden wurde, logge eine Warnung
        console.warn('Keine Icon-Informationen für listKey:', listKey);
        return fallbackIcon || {iconName: 'notdef', iconType: IconType.FontAwesome};
    }
}
