// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.has-children {
  background: #fff;
  border-radius: 50%;
  color: var(--color-accent);
  font-size: 14px;
  left: 26px;
  position: absolute;
  top: 26px;
  z-index: 1;
}
.has-children:hover {
  color: var(--color-default-dark);
}

.inline-icon {
  min-width: 12px;
  max-width: 18px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: baseline;
}

.img-inline-icon {
  width: 20px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: baseline;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/entity-icons/icon/icon.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AACJ;AACI;EACI,gCAAA;AACR;;AAGA;EACI,eAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,qBAAA;EACA,iBAAA;EACA,wBAAA;AAAJ;;AAIA;EACI,mEAAA;AADJ","sourcesContent":[":host {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.icon-wrapper {\n    position: relative;\n    display: inline-block;\n}\n\n.has-children {\n    background: #fff;\n    border-radius: 50%;\n    color: var(--color-accent);\n    font-size: 14px;\n    left: 26px;\n    position: absolute;\n    top: 26px;\n    z-index: +1;\n\n    &:hover {\n        color: var(--color-default-dark);\n    }\n}\n\n.inline-icon {\n    min-width: 12px;\n    max-width: 18px;\n    display: inline-block;\n    margin-right: 8px;\n    vertical-align: baseline;\n}\n\n.img-inline-icon {\n    width: 20px;\n    display: inline-block;\n    margin-right: 8px;\n    vertical-align: baseline;\n}\n\n// spinner color\n.mat-mdc-progress-spinner {\n    --mdc-circular-progress-active-indicator-color: var(--color-accent);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
