// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// Services
import {GlobalSearchService} from './global-search.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
// Environment einbinden
import {environment} from '@environment';

@Component({
    selector: 'phscw-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Läuft gerade ein Suchvorgang?
    searchInProgress = false;

    /*
     * Schalter, ob Suche angezeigt wird
     * 2018-11-27, PhS(MFe): Default geändert auf <false>
     */
    showSearch = false;

    // Bezeichnung des aktuellen Moduls
    currentModule = '';
    currentSubModule = '';

    // Suchbegriff
    searchInput = '';

    // Module in denen die Suche angezeigt werden soll
    modulesWithActiveSearch: string[] = environment.globalSearchActiveModules;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param changeDetector
     * @param globalSearchService
     */
    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private globalSearchService: GlobalSearchService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        /**
         * Beim ersten Aufruf kann in "initializeEventSubscriptions" noch nicht
         * auf das Router-Event gehört werden. Deswegen wird hier initial
         * onCurrentModuleChanged() aufgerufen.
         */
        this.onCurrentModuleChanged();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Router prüfen - wurde zu einem anderen Modul gewechselt?
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                this.onCurrentModuleChanged();
            });
    }

    /**
     * @brief   Auf Modul-Änderungen reagieren, damit Suche bei einigen
     *          Modulen ausgeblendet werden kann.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    onCurrentModuleChanged(): void {
        // beim Wechseln der Module Begriff zurücksetzen
        this.searchInput = '';
        // Zusätzlich im service zurücksetzen
        this.globalSearchService.searchInput$.next('');

        // Aktuelles Haupt-Modul speichern
        const splitRoute = this.router.url.split('/');
        this.currentModule = splitRoute[1];
        this.currentSubModule = splitRoute[2] || '';

        // Early-return falls es sich um Login-Seite handelt
        if (this.currentModule === 'login') {
            return;
        }

        /**
         * Globale Suche ist nicht in allen Modulen verfügbar.
         *
         * Wird in folgenden Hauptmodulen ausgeblendet:
         * - admin (Ausnahme: users)
         * - dashboards
         * - maps
         * - reports
         * - sales
         */
        if (
            this.modulesWithActiveSearch.includes(this.currentModule) ||
            this.modulesWithActiveSearch.includes(this.currentModule + '-' + this.currentSubModule)
        ) {
            this.showSearch = true;
        } else {
            this.showSearch = false;
        }

        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * @brief   Suche wird über ENTER im Suchfeld oder Klick auf Lupe
     * @details Wird aufgerufen in
     *          - Click-Listener auf Icon,
     *          - keyup.enter-Listener auf Input
     *          - und für Apple auch in keyup.shift.enter-Listener (https://github.com/angular/angular/issues/32963#issuecomment-537928154)
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    startSearch(): void {
        // Abbruch, falls kein Suchbegriff eingegeben wurde
        if (!this.searchInput) {
            return;
        }
        // Falls gerade eine Suche läuft, kann keine erneute Suche gestartet werden
        if (this.searchInProgress) {
            return;
        }
        // Falls weniger als drei Zeichen eingegeben wurden, wird keine Suche gestartet
        if (this.searchInput.length < 3) {
            return;
        }

        // Flag setzen
        this.searchInProgress = true;
        // Suche über GlobalSearchService starten
        const serviceRequest$ = this.globalSearchService.startSearch(this.searchInput);
        // Auf Request subscriben
        serviceRequest$.subscribe(
            // onNext
            (result: CWResult) => {
                // Antwort vom Backend erhalten - Flag ausschalten
                this.searchInProgress = false;

                // Change-Detection manuell auslösen
                this.changeDetector.detectChanges();
            },
            // onError
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
            },
        );
    }

    /**
     * Wird bei jeder Eingabe im Suchfeld aufgerufen
     * Benutzt, um lokale Suche zu starten (grid-new) - ohne Backend-Request
     * @param {string} value - Der aktuelle Wert des Suchfeldes
     */
    onSearchInputChange(value: string): void {
        this.globalSearchService.searchInput$.next(value);
    }
}
