// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Komponenten
import {EntityIconsComponent} from './entity-icons/entity-icons.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IconComponent} from './icon/icon.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    imports: [CommonModule, FontAwesomeModule, MatTooltipModule, RouterModule, MatProgressSpinnerModule],
    declarations: [EntityIconsComponent, IconComponent],
    exports: [EntityIconsComponent, IconComponent],
})
export class EntityIconsModule {}
