/**
 * @brief   Panel oberhalb eines Grids zur Anzeige der Filter-Buttons
 * @details Erhält ein Array mit Elementen vom Typ "FilterData" und stellt
 *          die darin enthaltenen Filtermöglichkeiten als Buttons dar.
 *          Klick auf einen Filter-Button wird an verbundenen Grid-Service
 *          weitergeleitet, damit das Grid auf die Änderung des Filters
 *          reagieren kann.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// GridComponent
import {GridComponent} from './../grid/grid.component';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';
// Interfaces für Structured Objects einbinden
import {FilterData} from './../../filter-data';
// Shared Components
import {PopupFormularContactFilterComponent} from '@shared/popups/popup-formular-contact-filter/popup-formular-contact-filter.component';
import {PopupFormularComponent} from '@shared/popups/popup-formular/popup-formular.component';
import {hasOwn} from '@shared/utils';
import {GridNewComponent} from '@shared/grid-new/grid-new.component';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {GridNewService} from '@shared/grid-new/shared/grid-new.service';

@Component({
    selector: 'phscw-grid-filter',
    templateUrl: './grid-filter.component.html',
    styleUrls: ['./grid-filter.component.scss'],
    animations: [
        trigger('filterAnimation', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden',
            })),
            state('closed', style({
                height: '0px',
                opacity: 0,
                margin: '0px',
                padding: '0px',
                overflow: 'hidden',
            })),
            transition('closed <=> open', [
                animate('300ms ease-in-out'),
            ]),
        ]),
    ],
})
export class GridFilterComponent implements OnInit, OnDestroy {
    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent | GridNewComponent<any>;
    // ID des (verbundenen) Grids
    @Input() gridId = '';

    // Ist diese Grid-Extension sichtbar?
    @Input() visible = false;

    // Filter-Felder
    @Input() filterFields: FilterData[] = [];

    // Zurücksetzen-Button beim Erweiterten Filter sichtbar?
    @Input() showAdvancedFilterResetButton = false;

    // Konstruktor
    constructor(
        private gridService: GridService,
        private filterDialog: MatDialog,
        private gridNewService: GridNewService<any>,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
    }

    // Aufräumen
    ngOnDestroy() {}

    // Prüfe Anforderungen
    checkRequirements() {
        // Diese Grid-Erweiterung benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridFilterComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    // Klick auf Filter-Button
    clickFilter(selectedFilterItem: FilterData) {
        // Falls beim angeklickten Filter ein Popup erscheinen soll
        if (hasOwn(selectedFilterItem, 'display')) {
            if (selectedFilterItem.display == 'popup') {
                this.openFilterDialog(selectedFilterItem);
                return;
            }
        }

        // Filter aktivieren
        this.activateFilter(selectedFilterItem);
    }

    // Filter aktivieren
    activateFilter(selectedFilterItem: FilterData) {
        // Button als "aktiv" markieren
        this.activateFilterButton(selectedFilterItem);
        // Filter-Infos an Grid weiterleiten
        this.forwardFilterToGrid(selectedFilterItem);
    }

    // Filter-Button als "aktiv" markieren (und alle anderen "deaktivieren")
    activateFilterButton(selectedFilterItem: FilterData) {
        // Vorhandene Filter-Buttons durchlaufen
        for (const filterfield of this.filterFields) {
            if (filterfield.key != selectedFilterItem.key) {
                // Alle anderen Sortierfelder sind nicht aktiv
                filterfield.active = false;
            } else {
                // Diesen Filter-Button als aktiv markieren
                filterfield.active = true;
            }
        }
    }

    // Gewählten Filter an Grid weiterleiten
    forwardFilterToGrid(selectedFilterItem: FilterData) {
        // GridService informieren (damit dort Event ausgelöst werden kann --> damit in übergeordneten Modulen z.B. die Settings gespeichert werden können)
        this.gridService.filterChanged(this.gridId, selectedFilterItem);
        this.gridNewService.filterChanged(this.gridId, this.gridId, selectedFilterItem);

        // Info zur geänderten Filterung über gridConnection direkt weiterreichen
        this.gridConnection.gridFilter = selectedFilterItem;
    }

    /**
     * @param selectedFilterItem
     * @brief   Erweiterten Filter-Dialog (als Popup) öffnen
     */
    openFilterDialog(selectedFilterItem: FilterData) {
        // Titel für Popup
        let popupTitle = 'Filter';
        if (hasOwn(selectedFilterItem, 'popupTitle')) {
            popupTitle = selectedFilterItem.popupTitle;
        }

        let componentRef;
        let popupData = null;
        if (hasOwn(selectedFilterItem, 'formular') && hasOwn(selectedFilterItem.formular, 'type')) {
            switch (selectedFilterItem.formular.type) {
                case 'contacts':
                    componentRef = PopupFormularContactFilterComponent;
                    break;
                default:
                    componentRef = PopupFormularComponent;
                    popupData = selectedFilterItem.formular.data ? selectedFilterItem.formular.data : [];
                    break;
            }
        }

        /*
         * Dialog konfigurieren und öffnen
         * @todo: Hier werden keine Daten fürs Formular mitgeliefert, da das Popup als Zwischenlösung ohnehin nur Kontakt-Filter anzeigt
         */
        const dialogRef = this.filterDialog.open(componentRef, {
            width: '450px',
            data: {
                title: popupTitle,
                message: '',
                data: popupData,
                showReset: this.showAdvancedFilterResetButton,
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.onFilterDialogAnswer(selectedFilterItem, result);
        });
    }

    /**
     * @param selectedFilterItem
     * @param popupResult
     * @brief   Falls Filter-Dialog-Popup geschlossen wurde
     */
    onFilterDialogAnswer(selectedFilterItem: FilterData, popupResult: any) {
        // Falls Antwort nicht auswertbar ist oder "Cancel" betätigt wurde, wird nichts weiter gemacht
        if (hasOwn(popupResult, 'answer')) {
            if (popupResult.answer === 'no' || popupResult.answer === 'cancel') {
                return;
            }
        } else {
            return;
        }

        // Init
        let targetFilter = null;
        // Formular-Daten (des Popups) an ausgewählten Filter hängen
        if (hasOwn(popupResult, 'formular')) {
            /*
             * Lösung für festen Kontaktfilter. @todo: raus sobald dort auch das reguläre FormularPopup verwendet wird
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular Daten aus Ergebnis ziehen
            filterItemCopy.formular = popupResult.formular;
            targetFilter = filterItemCopy;
        } else if (hasOwn(selectedFilterItem, 'formular')) {
            /*
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular mit Ergebnis überschreiben
            filterItemCopy.formular = popupResult;
            targetFilter = filterItemCopy;
            // Attribute, die nicht für den Filter notwendig sind, entfernen
            delete targetFilter.formular.answer;
            const filterKeys = Object.keys(targetFilter.formular);
            filterKeys.forEach((inputField: any) => {
                // "_input" in Attributnamen finden
                const hasInputName: boolean = inputField.includes('_input');
                // "_input" von Attributnamen entfernen
                const cleanAttributeName: string = inputField.replace('_input', '');
                /*
                 * Wenn der gefilterte Name bereits existiert, dann das Zusatzfeld aus dem Ergebnis löschen
                 * Wird hauptsächlich bei Jahres- und Monatseingabfeldern auftreten
                 */
                if (hasInputName && filterKeys.some((key: string) => key === cleanAttributeName)) {
                    delete targetFilter.formular[inputField];
                }
            });
        } else {
            // Gewählten Filter übergeben
            targetFilter = selectedFilterItem;
        }

        // Filter aktivieren
        this.activateFilter(targetFilter);
    }
}
