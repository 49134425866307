import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-spitex-customers',
    templateUrl: './chart-spitex-customers.component.html',
    styleUrl: './chart-spitex-customers.component.scss',
})
export class ChartSpitexCustomersComponent implements OnInit {
    @Input() chartLocation = 'dashboards';

    backendOptions: any;

    title: string = 'Spitex Neukunden';

    constructor() {}

    ngOnInit() {
        this.backendOptions = {
            series: 'spitexCustomers',
            series_goal: 'spitexCustomersGoals',
        };
    }
}
