/**
 * @brief   Shared PopupsModule zur Freigabe der Popup-Komponenten.
 * @details Um eine Popup-Komponente in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *
 *          // Shared Modules einbinden
 *          import {PopupsModule} from '../../shared/popups/popups.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// Angular-Material
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Komponenten dieses Shared Modules
import {PopupConfirmationComponent} from './popup-confirmation/popup-confirmation.component';
import {PopupFormularContactFilterComponent} from './popup-formular-contact-filter/popup-formular-contact-filter.component';
import {PopupFormularComponent} from './popup-formular/popup-formular.component';
import {PopupLoadingComponent} from './popup-loading/popup-loading.component';
import {PopupMessageComponent} from './popup-message/popup-message.component';
import {PopupSchedulingComponent} from './popup-scheduling/popup-scheduling.component';
import {PopupSelectEmployeesComponent} from './popup-select-employees/popup-select-employees.component';
import {PopupSignatureComponent} from './popup-signature/popup-signature.component';
// Shared Modules einbinden
import {GridModule} from '@shared/grid/grid.module';
import {InputModule} from '@shared/input/input.module';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        GridModule,
        InputModule,
        TranslateModule,
        MatDividerModule,
    ],
    declarations: [
        PopupConfirmationComponent,
        PopupFormularContactFilterComponent,
        PopupLoadingComponent,
        PopupMessageComponent,
        PopupSelectEmployeesComponent,
        PopupSchedulingComponent,
        PopupFormularComponent,
        PopupSignatureComponent,
    ],
    // Komponenten freigeben
    exports: [
        PopupConfirmationComponent,
        PopupFormularContactFilterComponent,
        PopupLoadingComponent,
        PopupMessageComponent,
        PopupSelectEmployeesComponent,
        PopupFormularComponent,
        TranslateModule,
    ],
    providers: [{
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            hasBackdrop: true,
            autoFocus: false,
        },
    }],
})
export class PopupsModule {}
