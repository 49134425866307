/**
 * @brief   Shared InputModule zur Freigabe der Input-Components.
 * @details Um die Input-Components in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {InputModule} from '../../shared/input/input.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Komponenten
import {InputAutocompleteComponent} from './input-autocomplete/input-autocomplete.component';
import {InputCheckboxComponent} from './input-checkbox/input-checkbox.component';
import {InputCounterComponent} from './input-counter/input-counter.component';
import {InputDateComponent} from './input-date/input-date.component';
import {InputHtmlEditorComponent} from './input-html-editor/input-html-editor.component';
import {InputMonthComponent} from './input-month/input-month.component';
import {InputMultipleAutocompleteComponent} from './input-multiple-autocomplete/input-multiple-autocomplete.component';
import {InputMultiselectComponent} from './input-multiselect/input-multiselect.component';
import {InputNumberComponent} from './input-number/input-number.component';
import {InputPlaygroundComponent} from './input-playground/input-playground.component';
import {InputRadioComponent} from './input-radio/input-radio.component';
import {InputSelectComponent} from './input-select/input-select.component';
import {InputSingleCheckboxComponent} from './input-single-checkbox/input-single-checkbox.component';
import {InputTextComponent} from './input-text/input-text.component';
import {InputTextareaComponent} from './input-textarea/input-textarea.component';
import {InputTimeComponent} from './input-time/input-time.component';
import {InputYearComponent} from './input-year/input-year.component';
// Pipe-Modul importieren
import {IsUndefinedPipe} from './is-undefined.pipe';
import {ReplaceSalesPlaceholdersPipe} from './replace-sales-placeholders.pipe';
import {ShowMultiSelectValuePipe} from './show-multi-select-value.pipe';
// HTML-Editor
import {NgSelectModule} from '@ng-select/ng-select';
import {QuillModule} from 'ngx-quill';
import {InputSelectGroupsComponent} from './input-select-groups/input-select-groups.component';
import {InputSelectSearchableComponent} from './input-select-searchable/input-select-searchable.component';
import {InputFormNotificationComponent} from './input-form-notification/input-form-notification.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatCardModule} from '@angular/material/card';
import {DirectivesModule} from '@shared/directives/directives.module';
import {MatIconModule} from '@angular/material/icon';
import {PipesModule} from '@shared/pipes/pipes.module';

@NgModule({
    // Importierte Module
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatCheckboxModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatTooltipModule,
        TranslateModule,
        QuillModule,
        NgSelectModule,
        FontAwesomeModule,
        MatCardModule,
        DirectivesModule,
        MatIconModule,
        PipesModule,
    ],
    // Komponenten dieses Shared Modules
    declarations: [
        InputCounterComponent,
        InputSelectComponent,
        InputTextComponent,
        InputTextareaComponent,
        InputDateComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        InputSingleCheckboxComponent,
        InputTimeComponent,
        InputAutocompleteComponent,
        InputNumberComponent,
        InputPlaygroundComponent,
        InputMultiselectComponent,
        ShowMultiSelectValuePipe,
        IsUndefinedPipe,
        ReplaceSalesPlaceholdersPipe,
        InputHtmlEditorComponent,
        InputMultipleAutocompleteComponent,
        InputMonthComponent,
        InputYearComponent,
        InputSelectGroupsComponent,
        InputSelectSearchableComponent,
        InputFormNotificationComponent,
    ],
    // Providers für den Datepicker
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE',
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useUtc: true},
        },
        IsUndefinedPipe,
        ReplaceSalesPlaceholdersPipe,
    ],
    // Komponenten, die dieses Shared Module freigeben soll
    exports: [
        InputCounterComponent,
        InputSelectComponent,
        InputTextComponent,
        InputTextareaComponent,
        InputDateComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        InputSingleCheckboxComponent,
        InputTimeComponent,
        InputAutocompleteComponent,
        InputNumberComponent,
        InputPlaygroundComponent,
        InputMultiselectComponent,
        ShowMultiSelectValuePipe,
        IsUndefinedPipe,
        ReplaceSalesPlaceholdersPipe,
        InputHtmlEditorComponent,
        InputMultipleAutocompleteComponent,
        InputMonthComponent,
        InputYearComponent,
        TranslateModule,
        InputSelectGroupsComponent,
        InputSelectSearchableComponent,
        InputFormNotificationComponent,
    ],
})
export class InputModule {}
