// Angular-Module
import {Component, Input, OnDestroy, OnInit, HostListener} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

// Highcharts
import * as Highcharts from 'highcharts';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
// Service des globalen Regionsfilters
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
// Service dieses Shared-Moduls
import {ChartService} from './../chart.service';

// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

declare let require: any;
const HighchartsMore = require('highcharts/highcharts-more.src');
// eslint-disable-next-line new-cap
HighchartsMore(Highcharts);
// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
// eslint-disable-next-line new-cap
HC_solid_gauge(Highcharts);

@Component({
    selector: 'phscw-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
    chartConstructor: any;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    // Aktuelle Route
    public activeRouteName: string;

    windowWidth: number;
    windowHeight: number;
    outerWidth: number;
    outerHeight: number;

    // To keep track of the timeout and cancel previous timeouts
    resizeTimeout: any;

    /**
     * *************************************************************************
     * Attribute die aus den gesetzten Parameter(HTML) und den Daten aus dem Backup gesetzt werden
     *************************************************************************
     */
    HighchartsOptions: any = {};
    chart: any = undefined;
    Highcharts = Highcharts;
    series: Highcharts.SeriesColumnOptions[] = [];
    categories: any[] = [''];
    amountSeries = 3;
    tickPositions: number[] = undefined;
    pane: any = undefined;
    chartOptions: any = undefined;
    selectedRegion: number = undefined;
    selectedDivision: number = undefined;
    colors = ['#a52f33', '#de9750', '#ffff9f', '#84c88b', '#008a7d'];
    dataExist = false;
    plotlines: any[] = [];
    plotlinesX: any[] = [];
    stackLabelsMapping = {};

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden(HTML) der ChartComponent gesetzt werden
     *************************************************************************
     */
    // bar, column, pie, solidgauge, packedbubble, line
    @Input() chartType = 'bar';
    @Input() title = 'Title';
    @Input() xAxisTitle = '';
    @Input() yAxisTitle = '';
    @Input() yAxisLabel: string = undefined;
    @Input() yAxisType = 'linear';
    @Input() tooltip = '{point.name} : {point.value}';
    @Input() min: number = undefined;
    @Input() minRange: number = undefined;
    @Input() max: number = undefined;
    @Input() margin: number[] = [undefined, undefined, undefined, undefined];
    @Input() overlap = false;
    @Input() stacking = false;
    @Input() dataLabels = false;
    @Input() dataLabelsFormat = '{point.name}';
    @Input() allowDecimals = true;
    @Input() chartLocation = '';
    @Input() overlapBackgroundColor: string = null;
    @Input() demoStyle = false;
    @Input() lineWidth = 0;
    @Input() markerSymbol: string = undefined;
    @Input() legendEnabled = true;
    @Input() tooltipFunction: any = null;
    @Input() enableStackLabel = false;
    @Input() tickInterval: number | false = false;
    @Input() markerEnabled = true;
    // optionale Argumente die ans backend geschickt werden
    _backendOptions = '';

    // Setter und getter für backendOptions
    @Input() set backendOptions(value: string) {
        this._backendOptions = value;
        this.resetAndReloadChart(true);
    }

    get backendOptions(): string {
        return this._backendOptions;
    }

    loading = false;

    // DataSource des Charts (Backend-Controller & Backend-Funktion)
    _dataBackendSource = '';

    // Setter und getter für dataBackendSource
    @Input() set dataBackendSource(value) {
        // Wert übernehmen
        this._dataBackendSource = value;
    }

    get dataBackendSource() {
        return this._dataBackendSource;
    }

    // Konstruktor
    constructor(
        private chartService: ChartService,
        private regionsfilterService: GlobalRegionsfilterService,
        private router: Router,
    ) {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.outerWidth = window.outerWidth;
        this.outerHeight = window.outerHeight;
    }

    // Initialisierungen
    ngOnInit() {
        this.loading = true;

        this.initializeEventSubscriptions();

        // generelle Optionen die für das Highcharts allgemein gesetzt werden
        Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
                loading: 'Daten werden geladen...',
                months: [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],
                weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                printChart: 'Drucken',
                rangeSelectorFrom: 'Von',
                rangeSelectorTo: 'Bis',
                rangeSelectorZoom: 'Zeitraum',
                downloadPNG: 'Download als PNG-Bild',
                downloadJPEG: 'Download als JPEG-Bild',
                downloadPDF: 'Download als PDF-Dokument',
                downloadSVG: 'Download als SVG-Bild',
                resetZoom: 'Zoom zurücksetzen',
                resetZoomTitle: 'Zoom zurücksetzen',
            },
        });

        if (hasOwn(environment, 'chartColors')) {
            this.colors = environment.chartColors;
        }

        this.resetAndReloadChart(true);
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        /*
         * Wenn Regionsfilter geändert wird
         * this._subscriptions.add(
         */
        this.regionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onEventRegionsfilterChanged(result);
            });
        // );

        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                if (route.url.toString() == '/dashboards') {
                    this.resetAndReloadChart(true);
                }
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        const newWidth = event.target.innerWidth;
        const newHeight = event.target.innerHeight;
        const newOuterWidth = window.outerWidth;
        const newOuterHeight = window.outerHeight;

        // Clear the previous timeout if there was one
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }

        // Set a new timeout that triggers after 10 seconds
        this.resizeTimeout = setTimeout(() => {
            if (newWidth !== this.windowWidth || newHeight !== this.windowHeight) {
                this.windowWidth = newWidth;
                this.windowHeight = newHeight;
            }

            if (newOuterWidth !== this.outerWidth || newOuterHeight !== this.outerHeight) {
                this.outerWidth = newOuterWidth;
                this.outerHeight = newOuterHeight;
            }

            this.drawChart();
        }, 500);
    }

    // Auf Event "eventRegionsfilterChanged" des globalen Regionsfilters reagieren
    onEventRegionsfilterChanged(result) {
        // Event-Daten
        if (result['target'].startsWith(this.chartLocation)) {
            const event: CWEvent = result;
            this.selectedRegion = event.data['region'];
            this.selectedDivision = event.data['division'];

            this.resetAndReloadChart(event.data['refreshGrid']);
        }
    }

    // bereitet die Daten für das Chart vor
    prepareChart(data: any) {
        if (data.stackLabels) {
            this.stackLabelsMapping = data.stackLabels;
        }

        this.amountSeries = Object.keys(data.series).length;

        this.series = [];
        let countSeries = 0;

        // fuer overlap farbauswahl
        let opaque = false;
        if (data.categories !== undefined && data.categories.length > 0) {
            // array empty or does not exist
            this.categories = data.categories;
        }

        // solidgauge benötigt Hintergrund
        if (this.chartType == 'solidgauge') {
            this.addPane();
        }

        // falls keine Daten vorhanden sind wird das Diagramm nicht angezeigt
        this.dataExist = false;

        // fügt eine/mehrere plotLine(s) falls gesetzt hinzu
        if (hasOwn(data, 'plotlines')) {
            this.plotlines = [];
            this.plotlinesX = [];
            if (!Array.isArray(data.plotlines)) {
                data.plotlines = [data.plotlines];
            }

            data.plotlines.forEach((plotline) => {
                if (plotline.axis == 'X') {
                    this.plotlinesX.push({
                        value: plotline.value,
                        color: this.convertHex('#666666', 60),
                        dashStyle: 'shortdash',
                        zIndex: 5,
                        width: 2,
                        label: {
                            text: plotline.label,
                            style: {color: this.convertHex('#666666', 60)},
                        },
                    });
                } else {
                    this.plotlines.push({
                        value: plotline.value,
                        color: this.convertHex('#666666', 60),
                        dashStyle: 'shortdash',
                        zIndex: 5,
                        width: 2,
                        label: {
                            text: plotline.label,
                            style: {color: this.convertHex('#666666', 60)},
                        },
                    });
                }
            });
        }

        const hasGoalSeries = Object.keys(data.series).some((seriesname) =>
            seriesname.toLowerCase().includes('ziel'));

        // läuft über die einzelnen serien
        Object.keys(data.series).forEach((seriesname) => {
            let size;
            let innerSize;

            if (this.chartType == 'pie') {
                innerSize = this.calculateRadius(countSeries, false) + '%';
            }

            let type = this.chartType;

            // falls eine Serie einen anderen Typ als das Hauptdiagramm hat
            if (hasOwn(data, 'types') && data.types[seriesname] != undefined) {
                type = data.types[seriesname];
            }

            const isGoalSeries = seriesname.toLowerCase().includes('ziel');

            const serie = {
                name: seriesname,
                type,
                data: [],
                showInLegend: isGoalSeries || !hasGoalSeries,
                size,
                innerSize,
                minPointLength: 3,
            } as Highcharts.SeriesColumnOptions;

            if (this.chartType == 'pie') {
                serie.dataLabels = {
                    formatter() {
                        return this.y >= 0 ? this.point.name + ': ' + this.y + '€' : null;
                    },
                    color: '#ffffff',
                    // distance: '-' + (missing + 10) + '%'
                };
                if (countSeries >= 0) {
                    serie.showInLegend = false;
                }
            }

            // läuft über die einzelenen Punkte der Serie
            data.series[seriesname].forEach((points) => {
                // einer der beiden werte muss gesetzt sein
                if (points['value'] == null) {
                    points['value'] = points['y'];
                }
                if (points['y'] == null) {
                    points['y'] = points['value'];
                }

                // sobald ein Punkt exisitert wird das Diagramm gezeigt
                if (points['y'] != null) {
                    this.dataExist = true;
                }

                // außer solidgauge werden Punkte gleich behandelt
                if (this.chartType != 'solidgauge') {
                    const tempPoint = points as Highcharts.Point;

                    serie['data'].push(tempPoint);
                } else {
                    // solidgauge benötigt keine tickPositions
                    this.tickPositions = [];

                    let color: string = this.colors[this.amountSeries - countSeries - 1];

                    if (points['color'] != undefined) {
                        color = points['color'];
                    }

                    const temp: Highcharts.PointOptionsObject = {
                        y: points['y'],
                        name: points['name'],
                        radius: this.calculateRadius(countSeries, true),
                        innerRadius: this.calculateRadius(countSeries, false),
                        color,
                    };

                    serie['data'].push(temp);
                }
            });

            // ueberlappende columns o. bar
            if (this.overlap && (this.chartType == 'column' || this.chartType == 'bar')) {
                // springt jeweils zwischen den hinterm und vorderen daten sätzen hin und her
                if (opaque) {
                    serie.pointPadding = 0.1;
                    const color = this.scaleBetween(countSeries - 1, this.amountSeries, this.colors.length);
                    serie.color = this.colors[color];
                } else {
                    serie.pointPadding = -0.1;

                    const color = this.scaleBetween(countSeries, this.amountSeries, this.colors.length);

                    if (this.overlapBackgroundColor == null) {
                        serie.color = this.convertHex(this.colors[color], 40);
                    } else {
                        serie.color = this.overlapBackgroundColor;
                    }
                }

                opaque = !opaque;
            } else if (data.colors == undefined) {
                const color = this.scaleBetween(countSeries, this.amountSeries, this.colors.length);
                serie.color = this.colors[color];
            } else {
                serie.color = data.colors[seriesname];
            }

            countSeries++;
            this.series.push(serie);
        });

        // this.series = series;
    }

    /**
     *
     * werte einsetzten und chart zeichnen
     *
     */
    drawChart() {
        const stackLabels = this.stackLabelsMapping;
        this.HighchartsOptions = {
            chart: {
                type: this.chartType,
                margin: this.margin,

                className: 'pharmakon-highcharts-' + this.chartType,
            },
            title: {
                text: this.title,
                align: 'left',
                style: {fontFamily: 'Roboto, sans-serif'},
            },
            credits: {enabled: false},
            xAxis: {
                title: {text: this.xAxisTitle},
                plotLines: this.plotlinesX,
                categories: this.categories,
                allowDecimals: this.allowDecimals,
            },
            yAxis: {
                title: {text: this.yAxisTitle},
                type: this.yAxisType,
                plotLines: this.plotlines,
                min: this.min == undefined ? undefined : Number(this.min),
                max: this.max == undefined ? undefined : Number(this.max),
                minRange: this.min == undefined ? undefined : Number(this.minRange),
                startOnTick: false,
                endOnTick: false,
                tickPositions: this.tickPositions,
                tickInterval: (this.tickInterval !== false) ? Math.round(Number(this.tickInterval)) : undefined,
                allowDecimals: this.allowDecimals,
                labels: {format: this.yAxisLabel},
                stackLabels: {
                    enabled: this.enableStackLabel,
                    formatter() {
                        let res = this.axis.chart.xAxis[0].categories[this.x];
                        if (hasOwn(stackLabels, this.axis.chart.xAxis[0].categories[this.x])) {
                            res = stackLabels[this.axis.chart.xAxis[0].categories[this.x]];
                        }
                        return res;
                    },
                },
            },
            legend: {enabled: this.legendEnabled},
            pane: this.pane,

            series: this.series,
            plotOptions: {
                series: {marker: {enabled: this.markerEnabled}},
                area: {fillOpacity: 1},
                packedbubble: {
                    height: '100%',
                    useSimulation: false,
                    minSize: '20%',
                    maxSize: '80%',
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}',
                        filter: {
                            property: 'y',
                            operator: '>',
                            value: 15,
                        },
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal',
                        },
                    },
                    tooltip: {
                        useHTML: true,
                        pointFormat: this.tooltip,
                    },
                },
                column: {
                    borderWidth: 0,
                    grouping: !this.overlap,
                    stacking: this.stacking ? 'normal' : undefined,
                    shadow: false,
                    tooltip: {
                        useHTML: true,
                        pointFormat: this.tooltip,
                    },
                    dataLabels: {
                        enabled: this.dataLabels,
                        format: this.dataLabelsFormat,
                    },
                    scrollbar: {enabled: true},
                },
                spline: {
                    lineWidth: this.lineWidth,
                    marker: {symbol: 'url(' + this.markerSymbol + ')'},
                    states: {hover: {lineWidthPlus: this.lineWidth}},
                },
                bar: {
                    borderWidth: 0,
                    dataLabels: {enabled: this.dataLabels},
                    grouping: !this.overlap,
                    stacking: this.stacking ? 'normal' : undefined,
                    tooltip: {
                        useHTML: true,
                        pointFormat: this.tooltip,
                    },
                    scrollbar: {enabled: true},
                },
                solidgauge: {
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                    dataLabels: {enabled: false},
                },
                pie: {borderWidth: 2},
            },
            tooltip: {
                useHTML: true,
                pointFormat: this.tooltip,
                followPointer: true,
                formatter: this.tooltipFunction,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                        ],
                    },
                },
            },
        };

        this.formatSolidGauge();
    }

    // Führt "resetGrid" und "loadData" aus
    resetAndReloadChart(result) {
        if (result && this._dataBackendSource != '') {
            this.loading = true;
            const completeChartOptions = {
                backendOptions: this.backendOptions,
                regions: {
                    division: this.selectedDivision,
                    region: this.selectedRegion,
                },
                location: this.chartLocation,
            };

            const serviceRequest$ = this.chartService.loadData(this._dataBackendSource, completeChartOptions);

            serviceRequest$.subscribe(
                (result: any) => {
                    this.prepareChart(result['data']);

                    this.drawChart();
                    // Flag "loading" deaktivieren
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                },
            );
        }
    }

    /**
     *
     *für Solidgauge wird ein Hintergrund (Pane) benötigt
     * @returns The processed target number
     */
    private addPane() {
        const pane = {
            startAngle: 0,
            endAngle: 360,
            background: [],
        };

        for (let i = 0; i < this.amountSeries; i++) {
            pane.background.push({
                outerRadius: this.calculateRadius(i, true) + '%',
                innerRadius: this.calculateRadius(i, false) + '%',
                borderWidth: 0,
            });
        }

        this.pane = pane;
    }

    /**
     *
     *rechnet die radius aus für solidgauge
     * @param {string} target  The target to process see {@link Todo}
     * @param {string} target  The target to process see {@link Todo}
     * @param count
     * @param outer
     * @returns The processed target number
     */
    private calculateRadius(count: number, outer: boolean) {
        if (outer) {
            return (70 / this.amountSeries) * (count + 1) + 30 - 1;
        }
        return (70 / this.amountSeries) * count + 30;
    }

    /**
     *
     *verschiebt eine
     * @param {string} target  The target to process see {@link Todo}
     * @param {string} target  The target to process see {@link Todo}
     * @param unscaledNum
     * @param max
     * @param maxAllowed
     * @returns The processed target number
     */
    private scaleBetween(unscaledNum: number, max: number, maxAllowed: number) {
        const result = (maxAllowed * unscaledNum) / max;

        if (isNaN(result)) {
            return 0;
        }

        return Math.round(result);
    }

    convertHex(hex: string, opacity: number) {
        hex = hex.replace('#', '');
        const r: number = parseInt(hex.substring(0, 2), 16);
        const g: number = parseInt(hex.substring(2, 4), 16);
        const b: number = parseInt(hex.substring(4, 6), 16);

        const result: string = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
        return result;
    }

    private formatSolidGauge() {
        if (this.chartType == 'solidgauge' && this.demoStyle) {
            this.HighchartsOptions.legend = {
                labelFormatter() {
                    return '<span style="text-weight:bold;color:' + this.data[0].color + '">' + this.name + '</span>';
                },
                symbolHeight: 1,
                symbolWidth: 1,
                reversed: true,
            };

            this.HighchartsOptions.tooltip = {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {fontSize: '12px'},
                pointFormat:
                    '<div style="with:30px; text-align:center;">{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold;">{point.y}%</span></div>',
                positioner(labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: this.chart.plotHeight / 2 - 20,
                    };
                },
            };
        } else if (this.chartType == 'solidgauge') {
            this.HighchartsOptions.legend = {
                labelFormatter() {
                    return (
                        '<span style="text-weight:bold;color:' + this.data[0].color + '">' + this.name + '</span>'
                    );
                },
                symbolHeight: 1,
                symbolWidth: 1,
                reversed: true,
            };
        }
    }

    // those were added for compling, please remove them/refctor them if you don't need them
    chartCallback() {}
    updateFromInput() {}
}
