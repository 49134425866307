import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Klassen einbinden
import {MenuData} from '@shared/menu-data';
// Globale Services einbinden
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Service dieses Shared-Moduls
import {GridService} from './../../grid.service';
// GridComponent
import {GridComponent} from './../grid.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../../cw-event';
import {CwIcon, IconType} from '@shared/cw-icon';

/**
 * Ausgelagerte Grid-Unterkomponente zur Anzeige von Icons im Grid
 * @author Michael Schiffner <m.schiffner@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-icon',
    templateUrl: './grid-icon.component.html',
    styleUrls: ['./grid-icon.component.scss', './../grid.component.scss'],
})
export class GridIconComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    #componentDestroyed$ = new Subject<void>();

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this.#name;
    }

    set name(name: string) {
        this.#name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    #name: string;

    iconType = IconType;

    // Variable zum Öffnen und Schließen
    hierarchyMenuOpen = false;
    // Beziehungstypen einer Einrichtung zur Anzeige im aufklappbaren Menü
    relationshipTypes: MenuData[] = [];
    // Gewählte Einrichtung
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickedElement: any = [];

    /*
     * Default Icons
     */
    defaultEventIcon: CwIcon = {
        iconName: 'icon-meeting',
        iconType: IconType.IconFont,
    };

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {InitService} initService - initService
     * @param {GridService} gridService - gridService
     * @param {StorageService} storageService - storageService
     * @param {MatTable} table - Mat
     */
    constructor(
        private initService: InitService,
        private gridService: GridService,
        private storageService: StorageService,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public table: MatTable<any>,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this.#name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
        // Initialisiere Icons
        this.initializeIcons();
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }

        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result) {
                this.initializeIcons();
            }
        });

        // Darauf warten, ob ein ListenElement mit Kindern angereichert wurde
        this.gridService.childrenElementLoaded
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (
                    event.sender !== this.gridConnection.gridId ||
                    event.target !== this.#name ||
                    event.data.level !== this.gridConnection.level
                ) {
                    return;
                }
                this.onChildrenElementLoaded(result);
            });
    }

    /**
     * Icons initialisieren
     */
    initializeIcons(): void {
        // Daten für "Einrichtungs-Icons" über Service aus IndexedDB anfordern
        if (this.gridConnection.useInstitutionsIcons && this.name === 'institution-icon') {
            const listName = 'institutionType1';
            const promise = this.storageService.getItem('listentries|' + listName);
            promise.then((val) => this.onGetListentriesFromStorage(listName, val));
        }
        // Daten für "Personen-Icons" über Service aus IndexedDB anfordern
        if (this.gridConnection.usePeopleIcons && this.name === 'person-icon') {
            const listName = 'personType1';
            const promise = this.storageService.getItem('listentries|' + listName);
            promise.then((val) => this.onGetListentriesFromStorage(listName, val));
        }
    }

    /**
     * @description Listentry-Daten wurden geladen
     * @param {string} listname - Name der Liste
     * @param {any} storageData - Daten aus dem Storage
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onGetListentriesFromStorage(listname: string, storageData: any): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries - nicht schön aber (leider auch nicht) selten
        if (storageData == null) {
            return;
        }

        // Daten (für icons) aus Storage vorhanden
        if (storageData) {
            // Werte der Liste (list_key & list_value) in Select-Optionen (id & label) übernehmen
            for (const storageItem of storageData) {
                // Prüfe, ob überhaupt etwas in "list_data" eingetrage wurde
                if (storageItem['list_data']) {
                    const listDataObject = JSON.parse(storageItem['list_data']) as CwIcon;
                    if (listname === 'institutionType1') {
                        this.gridConnection.institutionsIcons.push({
                            key: storageItem['list_key'],
                            iconName: listDataObject.iconName,
                            iconType: listDataObject.iconType,
                            iconPath: listDataObject.iconPath,
                        });
                    }
                    if (listname === 'personType1') {
                        this.gridConnection.peopleIcons.push({
                            key: storageItem['list_key'],
                            iconName: listDataObject.iconName,
                            iconType: listDataObject.iconType,
                            iconPath: listDataObject.iconPath,
                        });
                    }
                }
            }
            // Prüfe, ob bereits Grid-Daten vorliegen, da diese nun nachträglich um Icons angereichert werden sollten
            if (this.gridConnection.gridData.length > 0) {
                this.gridConnection.gridData = this.gridConnection.enrichData(this.gridConnection.gridData);
            }
        }
    }

    /**
     * Klick auf Icon
     * @param {number} id -
     */
    clickIcon(id: number): void {
        if (this.name === 'institution-icon') {
            this.gridService.institutionIconClicked(this.gridConnection.gridId, id);
        } else if (this.name === 'person-icon') {
            this.gridService.personIconClicked(this.gridConnection.gridId, id);
        } else if (this.name === 'event-icon') {
            this.gridService.eventIconClicked(this.gridConnection.gridId, id);
        } else {
            this.gridService.iconClicked(this.gridConnection.gridId, id);
        }
    }

    /**
     * Blendet das Hierarchie-Menü einer Einrichtung ein bzw. aus und
     * lädt ggf. die Anzahl der Kindeinrichtungen pro Beziehungstyp
     * @param {any} institution - Die Einrichtung, für die das Hierarchie-Menü eingeblendet werden soll
     * @author  Dominik Treutle <d.treutle@pharmakon.software>
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleHierarchyMenu(institution: any): void {
        // Blende die Kindeinrichtungen aus, wenn dieselbe Einrichtung nochmal angeklickt wird
        if (this.gridConnection.expandedElement === institution) {
            this.gridConnection.expandedElement = null;
            this.hierarchyMenuOpen = false;
        } else if (this.hierarchyMenuOpen && this.clickedElement === institution) {
            this.hierarchyMenuOpen = false;
        } else if (this.hierarchyMenuOpen && this.clickedElement !== institution) {
            this.clickedElement = institution;
            this.hierarchyMenuOpen = true;
        } else if (!this.hierarchyMenuOpen && this.clickedElement === institution) {
            this.hierarchyMenuOpen = true;
        } else if (!this.hierarchyMenuOpen && this.clickedElement !== institution) {
            this.clickedElement = institution;
            this.hierarchyMenuOpen = true;
        }

        this.relationshipTypes.length = 0;
        // Lade die Anzahl der Kindeinrichtungen einer Einrichtung pro Beziehungstyp
        if (this.hierarchyMenuOpen) {
            const serviceRequest$ = this.gridService.getNumberOfChildren(institution['id']);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            serviceRequest$.subscribe((result: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const children: any = result['data'];
                // wenn nur ein relationship typ existiert, direkt mini grid öffnen
                if (typeof children !== 'undefined' && children.length === 1) {
                    this.showChildren(institution, children[0].list_key);
                }

                // wenn mehre typen existieren, popup öffnen
                if (typeof children !== 'undefined' && children.length > 1) {
                    for (const child of children) {
                        const label = child.label + ' (' + child.countChildren + ')';

                        // kommt übersetzt aus dem backend
                        this.relationshipTypes.push({
                            name: child.list_key,
                            shownText_deu: label,
                            shownText_eng: label,
                            shownText_fra: label,
                            icon: '',
                        });
                    }
                }
            });
        }
    }

    /**
     * Zeige die "Kinder" einer Einrichtung an
     * @param {any} institution - Die Einrichtung, für die die Kindeinrichtungen angezeigt werden sollen
     * @param {any} relationshipType - Der Beziehungstyp
     * @author  Dominik Treutle <d.treutle@pharmakon.software>
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showChildren(institution: any, relationshipType: any): void {
        // Wenn die Children schon einmal geladen wurden und es nur eine Art von Children gibt, müssen diese nicht erneut aus dem Backend geladen werden.
        if (typeof institution['children'] !== 'undefined' && this.relationshipTypes.length < 2) {
            this.gridConnection.expandedElement = institution;
            this.hierarchyMenuOpen = false;
            // Change detection auslösen
            this.gridService.triggerGridChangeDetection();
            return;
        }
        // ... Nur wenn die Abteilungen noch nicht geladen worden sind, diese neu aus dem Backend laden.
        this.gridConnection.expandedElement = institution;
        this.hierarchyMenuOpen = false;
        const institutionInfo = [{
            id: institution['id'],
            relationshipType,
        }];
        // Lade die Kindeinrichtungen nur, wenn diese zuvor noch nicht geladen wurden
        this.gridConnection.childrenLoading = true;
        // Lade alle IDs der Kindeinrichtungen, die die gewählte Einrichtung als parent haben
        const serviceRequest$ = this.gridService.getInstitutionChildren(institutionInfo);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        serviceRequest$.subscribe((result: any) => {
            // Die Children werden nochmal mit den Grid-Icons angereichert
            // eslint-disable-next-line no-param-reassign
            institution['children'] = this.gridConnection.enrichData(result['data']['list_data']);
            this.gridConnection.childrenLoading = false;
            // Change detection auslösen
            this.gridService.triggerGridChangeDetection();
        });
    }

    /**
     * @description Zeige die "Kinder" eines beliebigen Listentries an.
     * @param {any} element - Das Element, dessen Kinder angezeigt werden sollen
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showGeneralChildren(element: any): void {
        // Blende die Kindeinrichtungen aus, wenn dieselbe Einrichtung nochmal angeklickt wird
        if (this.gridConnection.expandedElement === element) {
            this.gridConnection.expandedElement = null;
            return;
        }
        // Wenn die Children schon einmal geladen wurden und es nur eine Art von Children gibt, müssen diese nicht erneut aus dem Backend geladen werden.
        if (typeof element['children'] !== 'undefined') {
            this.gridConnection.expandedElement = element;
            return;
        }

        this.gridConnection.expandedElement = element;
        // Lade die Kinder-Elemente nur, wenn diese zuvor noch nicht geladen wurden
        this.gridConnection.childrenLoading = true;
        this.gridService.getElementChildren(element, this.gridConnection.gridId, this.#name, this.gridConnection.level);
        this.gridService.triggerGridChangeDetection();
    }

    /**
     * @description Wird aufgerufen, wenn die Kinder-Elemente einer Entität geladen wurden
     * @param {CWEvent} event - Event-Daten
     */
    onChildrenElementLoaded(event: CWEvent): void {
        if (
            this.gridConnection.expandedElement !== null &&
            // eslint-disable-next-line eqeqeq
            this.gridConnection.expandedElement.id == event.data.element.id
        ) {
            if (typeof event.data.element.children !== 'undefined') {
                this.gridConnection.expandedElement['children'] = event.data.element.children;
            } else {
                this.gridConnection.expandedElement.hasChildren = false;
            }
        }
        // Change detection auslösen
        this.gridService.triggerGridChangeDetection();
        this.gridConnection.childrenLoading = false;
    }
}
