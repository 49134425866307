/* eslint-disable no-console */
import {Component, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import {CwIcon, IconType} from '@shared/cw-icon';
import {IconService} from '@global/services/icon.service';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {IconName} from '@fortawesome/pro-solid-svg-icons';
import {hasOwn} from '@shared/utils';

/**
 * @description
 * Komponente zur Darstellung von Icons.
 * Die Priorität, welches Icon verwendet wird:
 * 1. iconName (erstellt ein FontAwesome Icon)
 * 2. icon (vollständiges Icon-Objekt)
 * 3. listName & listKey (wird über den IconService aus Listeneinträgen ermittelt)
 * 4. Fallback: fallbackIcon, useInstitutionFallbackIcon bzw. usePersonFallbackIcon oder Standard-"notdef"
 */
@Component({
    selector: 'phscw-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
    @Input() icon?: CwIcon;
    @Input() iconName?: string;
    @Input() listName?: string;
    @Input() listKey?: string;
    @Input() fallbackIcon?: CwIcon;
    @Input() tooltip?: string;
    @Input() iconClass?: string;
    @Input() iconColor?: string;
    @Input() isExpanded: boolean = false;
    @Input() isExpandable: boolean = false;
    @Input() isInlineIcon: boolean = false;
    @Input() routerLink?: string | any[] | null | undefined;
    @Input() useInstitutionFallbackIcon = false;
    @Input() usePersonFallbackIcon = false;

    @Output() iconClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() expandToggled: EventEmitter<void> = new EventEmitter<void>();

    notFoundIcon: CwIcon = {
        iconName: 'notdef',
        iconType: IconType.FontAwesome,
    };

    // Internes Icon, das angezeigt wird
    displayIcon: CwIcon = this.notFoundIcon;

    // Standardpfad für SVG Icons
    defaultAssetsIconPath: string = 'assets/img/icons/';

    readonly IconType = IconType;

    /**
     * @description
     * Calculates the path to the SVG icon.
     * If the internally set `displayIcon` has an `iconPath`, it is used.
     * Otherwise, the default path is used.
     * If the `displayIcon` is invalid (no `iconName`), the "notdef" icon path is used.
     *
     * If no svg found, @see {@link onImageError()}
     * @returns {string} The path to the SVG icon.
     */
    get iconPath(): string {
        const notDefIconPath = `${this.defaultAssetsIconPath}notdef.svg`;

        if (!this.displayIcon || !this.displayIcon.iconName) {
            console.error('Ungültige Icon-Konfiguration:', this.displayIcon);
            return notDefIconPath;
        }

        if (this.displayIcon.iconPath) {
            return `${this.displayIcon.iconPath}${this.displayIcon.iconName}.svg`;
        }

        return notDefIconPath;
    }

    // indicates whether the icon is currently being loaded
    isLoading: boolean = false;

    constructor(private iconService: IconService, private faIconLibrary: FaIconLibrary) {}

    /**
     * Called when any of the input properties change.
     * Updates the internal `displayIcon` property based on the input properties' priority order:
     * 1. Direct `iconName` (FontAwesome)
     * 2. Full `icon` object
     * 3. Look up via `listName` & `listKey` (asynchronous)
     * 4. Fallback (uses `fallbackIcon` or default `"notdef"` icon)
     * @param {SimpleChanges} changes - Object containing the changed input properties.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges): void {
        // Priority 1: Direct iconName
        if (this.iconName) {
            this.displayIcon = {
                iconName: this.iconName,
                iconType: IconType.FontAwesome,
            };
            this.validateFontAwesomeIcon(); // Validate iconName in library
            return;
        }

        // Priority 2: Full icon object provided
        if (this.icon) {
            this.displayIcon = this.icon;
            this.validateFontAwesomeIcon(); // Validate if needed
            return;
        }

        // Priority 3: Look up via listName & listKey (asynchronous)
        if (this.listName && this.listKey) {
            this.loadIconFromList();
            // Temporarily, set the fallback while loading
            this.displayIcon = this.getFallbackIcon();
            return;
        }

        if (this.listName || this.listKey) {
            console.warn('Warnung: Es wurden entweder listName oder listKey übergeben. Beide müssen gesetzt sein.', {
                listName: this.listName,
                listKey: this.listKey,
            });
            this.displayIcon = this.getFallbackIcon();
            return;
        }

        // Priority 4: Fallback
        this.displayIcon = this.getFallbackIcon();

        // Validate if the icon is a FontAwesome icon
        this.validateFontAwesomeIcon();

        // Override icon color if iconColor is provided
        if (this.iconColor) {
            this.displayIcon = {
                ...this.displayIcon,
                color: this.iconColor,
            };
        }
    }

    /**
     * Validates that a FontAwesome icon exists in the library.
     * If it doesn't, the fallback icon is used.
     */
    private validateFontAwesomeIcon(): void {
        const hasNoIconType = !hasOwn(this.displayIcon, 'iconType') || this.displayIcon.iconType === undefined || this.displayIcon.iconType === null;
        const hasIconName = hasOwn(this.displayIcon, 'iconName') && this.displayIcon.iconName !== undefined && this.displayIcon.iconName !== null;

        // If iconName is provided and iconType is not provided, assume it's a FontAwesome icon
        if (hasIconName && hasNoIconType) {
            this.displayIcon = {
                ...this.displayIcon,
                iconType: IconType.FontAwesome,
            };
        }

        if (this.displayIcon.iconType === IconType.FontAwesome) {
            const iconDef = this.faIconLibrary.getIconDefinition('fas', this.displayIcon.iconName as IconName);
            // If the icon is not found in the library, fallback to the default icon.
            if (!iconDef) {
                console.warn(
                    `Icon with name "${this.displayIcon.iconName}" and prefix "fas" not found in FontAwesome library. Falling back to default icon.`,
                );
                this.displayIcon = this.getFallbackIcon();
            }
        }
    }

    /**
     * Loads the icon asynchronously from the listName and listKey using the IconService.
     * The loaded icon is then set as the displayIcon.
     * If iconColor is set, the color is overridden.
     * If the loading fails, the fallback icon is used.
     */
    private async loadIconFromList(): Promise<void> {
        this.isLoading = true;

        try {
            const icon = await this.iconService.getIconForListentry(
                this.listName as string,
                this.listKey as string,
                this.getFallbackIcon(),
            );
            this.displayIcon = icon;

            // If iconColor is set, override the color.
            if (this.iconColor) {
                this.displayIcon = {
                    ...this.displayIcon,
                    color: this.iconColor,
                };
            }
        } catch (error) {
            console.error('Fehler beim Laden des Icons aus Listeneinträgen:', error);
            this.displayIcon = this.getFallbackIcon();
        } finally {
            // Loading is complete
            this.isLoading = false;
        }
    }

    /**
     * Handles the click event on the icon.
     * @param {MouseEvent} event The click event.
     */
    handleClick(event: MouseEvent): void {
        event.stopPropagation();
        /**
         * Emits the click event.
         * @event iconClicked
         */
        this.iconClicked.emit(event);
    }

    /**
     * Toggles the expanded state of the component.
     * @param {Event} event event The click event.
     */
    toggleExpand(event: Event): void {
        // Toggle the expanded state
        this.isExpanded = !this.isExpanded;
        event.stopPropagation();

        // Emit the expand toggled event
        this.expandToggled.emit();
    }

    /**
     * Called when the SVG icon fails to load.
     * Swaps the displayed icon to the fallback icon.
     * @param {Event} event - The error event from the image.
     */
    onImageError(event: Event): void {
        // Cast to HTMLImageElement to update the src attribute if needed.
        const imageElement = event.target as HTMLImageElement;

        // Display the fallback icon.
        this.displayIcon = this.getFallbackIcon();

        // Clear the image source to avoid the broken image being displayed.
        imageElement.src = '';
    }

    /**
     * Returns the appropriate fallback icon based on the priority:
     * 1. Institutional fallback icon if `useInstitutionFallbackIcon` is true.
     * 2. Person fallback icon if `usePersonFallbackIcon` is true.
     * 3. Provided fallback icon if available.
     * 4. Default not-found icon.
     * @returns {CwIcon} The fallback icon
     */
    private getFallbackIcon(): CwIcon {
        // Check if the institution fallback icon should be used
        if (this.useInstitutionFallbackIcon) {
            return {
                iconName: 'icon-institution',
                iconType: IconType.IconFont,
            };
        }

        // Check if the person fallback icon should be used
        if (this.usePersonFallbackIcon) {
            return {
                iconName: 'icon-user',
                iconType: IconType.IconFont,
            };
        }

        // Return the specified fallback icon or default to the not-found icon
        return this.fallbackIcon || this.notFoundIcon;
    }
}
