/**
 * @description   Formular-Komponente für den erweiterten Filter der Kontaktsübersicht
 *          Dies soll (eigentlich) eine generelle Popup-Komponente inklusive
 *          eines Formular sein. Aktuell befindet sich (aus Zeitgründen)
 *          jedoch die fest implementierte Version des Filters für Kontakte der
 *          Kontaktübersicht darin. Sobald mal Zeit ist / spätestens wenn ein
 *          anderes Popup mit Formular benötigt wird, sollte man das
 *          generalisieren.
 *          Alternativ könnte man den Kontaktfilter auch unter
 *              shared/contacts/contact-filter
 *          separat ablegen.
 *          Da dies aber ohnehin nur eine IVF-Zwischenlösung ist, kann
 *          ich aktuell mit diesem Architektur-Defizit leben.
 *          - 2020-11-10 PhS(TH): Komponente umbenannt und allgemeine
 *          Formular-Komponente für Aktions-Modul als PopupFormularComponent
 *          angelegt
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
// Angular-Module
import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Globale Services
import {UserSettingsService} from '@global/services/user-settings.service';
// Services anderer Shared-Modules
import {InputDateService} from './../../input/input-date/input-date.service';
// Interfaces für Structured Objects einbinden
import {SelectData} from '@shared/select-data';
import {AdminRegionsService} from '@modules/admin/admin-regions/admin-regions.service';
import {AdminProductsService} from '@modules/admin/admin-products/admin-products.service';

// Moment-Modul zur Datums-Verarbeitung
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;

// Interface für Dialogdaten
export interface PopupFormularData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    module: any;
    title: string;
    message: string;
    answer: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formular?: any;
}

@Component({
    selector: 'phscw-popup-formular-contact-filter',
    templateUrl: './popup-formular-contact-filter.component.html',
    styleUrls: ['./popup-formular-contact-filter.component.scss'],
})
export class PopupFormularContactFilterComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    #componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('popupForm', {static: true}) popupForm: NgForm;

    // Daten des Formulars
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() dataItem: any = {};
    divisions = [];
    products = [];

    // Definiert ob bei Kontakten "Dauer" oder "Von / Bis" angezeigt wird --> im Filter wird immer VON und BIS angezeigt
    contactsUseDuration = false;

    // Einträge für Selectbox "Erledigt"
    selectFieldsDone: SelectData[] = [
        {
            id: 'all',
            label: '',
        },
        {
            id: 'N',
            label: 'Offen',
        },
        {
            id: 'Y',
            label: 'Erledigt',
        },
    ];

    // Gespeicherte Einstellunge für Filter
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storedFilter: any = {};

    // Formular-Felder
    formFields: string[] = [
        'contact_type',
        'contact_method',
        'contact_start_date',
        'contact_start_time',
        'contact_end_date',
        'contact_end_time',
        'contact_priority',
        'responsible_employee_id',
        'division_id',
        'product_id',
        'contact_note',
    ];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {TranslateService} translateService Service für Übersetzungen
     * @param {InputDateService} inputDateService inputDateService
     * @param {UserSettingsService} userSettingsService userSettingsService
     * @param {MatDialogRef} dialogRef Referenz auf Dialog
     * @param {any} data Dialog-Daten
     * @param {AdminRegionsService} adminRegionsService adminRegionsService
     * @param {AdminProductsService} adminProductsService adminProductsService
     */
    constructor(
        private translateService: TranslateService,
        private inputDateService: InputDateService,
        private userSettingsService: UserSettingsService,
        public dialogRef: MatDialogRef<PopupFormularContactFilterComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: PopupFormularData,
        private adminRegionsService: AdminRegionsService,
        private adminProductsService: AdminProductsService,
    ) {
        // eslint-disable-next-line no-param-reassign
        dialogRef.disableClose = true;
    }

    /**
     * Initialize
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Ermittle gespeicherten erweiterten Filter für Kontaktübersicht
        this.loadStoredFilter();
        // Divisionen für die Division-Select-Box initial laden
        this.loadDivisions();
        this.loadProducts();
        this.initializeFormular();
    }

    loadDivisions(): void {
        const serviceRequest$ = this.adminRegionsService.loadDivisions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        serviceRequest$.subscribe((result: any) => {
            this.divisions = result['data'];
        });
    }

    loadProducts(): void {
        const serviceRequest$ = this.adminProductsService.loadProducts();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        serviceRequest$.subscribe((result: any) => {
            this.products = result['data'];
        });
    }

    /**
     * Cleanup
     */
    ngOnDestroy() {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * @description   Übersetzungen subscriben
     *                Subscribe auf Stream bekommt Änderung der Sprache mit
     *                und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['GENERAL.DONE', 'GENERAL.UNDONE'])
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((translation: string[]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.selectFieldsDone.find((column: any) => column.id === 'Y').label = translation['GENERAL.DONE'];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.selectFieldsDone.find((column: any) => column.id === 'N').label = translation['GENERAL.UNDONE'];
            });
    }

    initializeFormular(): void {
        if (this.storedFilter && hasOwn(this.storedFilter, 'division_id') && this.storedFilter['division_id'] !== null) {
            this.dataItem['division_id'] = this.storedFilter['division_id'];
        } else {
            this.dataItem['division_id'] = '';
        }
        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_type') && this.storedFilter['contact_type'] !== null) {
            this.dataItem['contact_type'] = this.storedFilter['contact_type'];
        } else {
            this.dataItem['contact_type'] = '';
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_method') && this.storedFilter['contact_method'] !== null) {
            this.dataItem['contact_method'] = this.storedFilter['contact_method'];
        } else {
            this.dataItem['contact_method'] = '';
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_priority') && this.storedFilter['contact_priority'] !== null) {
            this.dataItem['contact_priority'] = this.storedFilter['contact_priority'];
        } else {
            this.dataItem['contact_priority'] = '';
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_start_date') && this.storedFilter['contact_start_date'] !== null) {
            this.dataItem['contact_start_date'] = this.storedFilter['contact_start_date'];
        } else {
            // Anfang des Jahres
            this.dataItem['contact_start_date'] = null;
        }
        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_start_time') && this.storedFilter['contact_start_time'] !== null) {
            this.dataItem['contact_start_time'] = this.storedFilter['contact_start_time'];
        } else {
            this.dataItem['contact_start_time'] = null;
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_end_date') && this.storedFilter['contact_end_date'] !== null) {
            this.dataItem['contact_end_date'] = this.storedFilter['contact_end_date'];
        } else {
            // Ende des Jahres
            this.dataItem['contact_end_date'] = null;
        }
        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_end_time') && this.storedFilter['contact_end_time'] !== null) {
            this.dataItem['contact_end_time'] = this.storedFilter['contact_end_time'];
        } else {
            this.dataItem['contact_end_time'] = null;
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'done') && this.storedFilter['done'] !== null) {
            this.dataItem['done'] = this.storedFilter['done'];
        } else {
            this.dataItem['done'] = 'all';
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'responsible_employee_id') && this.storedFilter['responsible_employee_id'] !== null) {
            this.dataItem['responsible_employee_id'] = this.storedFilter['responsible_employee_id'];
        } else {
            this.dataItem['responsible_employee_id'] = null;
        }

        if (
            this.storedFilter &&
            hasOwn(this.storedFilter, 'responsible_employee_fullname') &&
            this.storedFilter['responsible_employee_fullname'] !== null
        ) {
            this.dataItem['responsible_employee_fullname'] = this.storedFilter['responsible_employee_fullname'];
        } else {
            this.dataItem['responsible_employee_fullname'] = '';
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'product_id') && this.storedFilter['product_id'] !== null) {
            this.dataItem['product_id'] = this.storedFilter['product_id'];
        } else {
            this.dataItem['product_id'] = null;
        }

        if (this.storedFilter && hasOwn(this.storedFilter, 'contact_note') && this.storedFilter['contact_note'] !== null) {
            this.dataItem['contact_note'] = this.storedFilter['contact_note'];
        } else {
            this.dataItem['contact_note'] = '';
        }
    }

    clickFilterReset(): void {
        this.storedFilter = {};
        this.initializeFormular();
    }

    prepareDataItem(): void {
        if (this.dataItem['contact_start_date']) {
            this.dataItem['contact_start_date_backendsave'] = this.inputDateService.getDateValueForSave(
                this.dataItem['contact_start_date'],
            );
        }
        if (this.dataItem['contact_end_date']) {
            this.dataItem['contact_end_date_backendsave'] = this.inputDateService.getDateValueForSave(
                this.dataItem['contact_end_date'],
            );
        }
    }

    /**
     * Mitarbeiter wurde ausgewählt, setze Filterdaten
     * @param {object} selectedOption Ausgewählte Option aus dem autocomplete
     * @param {number} selectedOption.id - Id des Mitarbeiters
     * @param {string} selectedOption.label - Name des Mitarbeiters
     */
    changeEmployee(selectedOption: {id: number;
        label: string;}): void {
        this.dataItem.responsible_employee_fullname = selectedOption.label;
        this.dataItem.responsible_employee_id = selectedOption.id;
    }

    /*
     * Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
     */
    clickAndClose(answer: string): void {
        // Divisions müssen rausgeschmissen werden, sonst wirft das Backend Fehler
        if (
            typeof this.dataItem['division_id'] === 'undefined' ||
            this.dataItem['division_id'] === null ||
            this.dataItem['division_id'] === ''
        ) {
            delete this.dataItem['division_id'];
        }

        this.data.answer = answer;
        this.prepareDataItem();
        this.data.formular = this.dataItem;

        this.dialogRef.close(this.data);
        // Speichert den Filter in den Usersettings, wenn die Antwort "okay" ist
        if (this.data.answer === 'okay') {
            if (this.data?.module === 'institutionsContacts') {
                this.userSettingsService.setValue(
                    'reportsInsitutionContactsListFilterAdvanced',
                    JSON.stringify(this.dataItem),
                );
            } else if (this.data?.module === 'peopleContacts') {
                this.userSettingsService.setValue(
                    'reportsPeopleContactsListFilterAdvanced',
                    JSON.stringify(this.dataItem),
                );
            } else {
                this.userSettingsService.setValue('reportsContactsListFilterAdvanced', JSON.stringify(this.dataItem));
            }
        }
    }

    /**
     * @description   End-Datum korrigieren unter bestimmten Vorraussetzungen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    correctContactEndDate(): void {
        if (
            !this.contactsUseDuration &&
            this.dataItem.contact_start_date &&
            typeof this.dataItem.contact_start_date !== 'undefined' &&
            this.dataItem.contact_start_date.isAfter(this.dataItem.contact_end_date, 'day')
        ) {
            this.dataItem.contact_end_date = this.dataItem.contact_start_date;
        }
    }

    /**
     * @param {any} value Wert, der geprüft wird
     * @description   Min Endzeit setzen je nachdem ob Start- und Enddatum gleich oder unterschiedlich sind
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @returns {boolean} true, wenn value ein Moment-Objekt ist
     */
    isMomentObject(value): boolean {
        return moment.isMoment(value);
    }

    /**
     * @description  Lädt den Filter aus den UserSettings
     */
    loadStoredFilter(): void {
        if (this.data?.module === 'institutionsContacts') {
            this.storedFilter = JSON.parse(
                this.userSettingsService.getValue('reportsInsitutionContactsListFilterAdvanced'),
            );
        } else if (this.data?.module === 'peopleContacts') {
            this.storedFilter = JSON.parse(
                this.userSettingsService.getValue('reportsPeopleContactsListFilterAdvanced'),
            );
        } else {
            this.storedFilter = JSON.parse(this.userSettingsService.getValue('reportsContactsListFilterAdvanced'));
        }
    }
}
