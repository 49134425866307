import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-spitex-app-users',
    templateUrl: './chart-spitex-app-users.component.html',
    styleUrl: './chart-spitex-app-users.component.scss',
})
export class ChartSpitexAppUsersComponent implements OnInit {
    @Input() chartLocation = 'dashboards';

    backendOptions: any;

    title: string = 'App Benutzer';

    constructor() {}

    ngOnInit() {
        this.backendOptions = {
            series: 'spitexAppUsers',
            series_goal: 'spitexAppUsersGoals',
        };
    }
}
